body.study-section-page {
  .view-display-id-header_landing_page {
    .header-background {
      max-height: 400px;

      picture {
        &:before {
          background: $slider-ol-left no-repeat !important;
          opacity: 1 !important;
          height: 400px !important;
          width: 408px !important;
        }

        &:after {
          background: $slider-ol-right no-repeat;
          content: "";
          display: block;
          height: 400px;
          width: 233px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }
      }
    }
  }
}
