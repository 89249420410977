@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    display: auto;
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: darkgrey;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $trolly-grey;
  }
}

@mixin plus-icons {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 62px;
  height: 62px;
  z-index: 4;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    width: 18px;
    height: 2px;
    background-color: $jet;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
  &:after {
    content: "";
    display: block;
    width: 18px;
    height: 2px;
    transform: rotate(90deg);
    background-color: $jet;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
  &:hover {
    &:before {
      transform: rotate(360deg);
      transition: 0.5s ease all;
    }

    &:after {
      transform: rotate(270deg);
      transition: 0.5s ease all;
    }
  }
  &.children-expanded {
    &:hover {
      &:before {
        transform: rotate(0deg);
        transition: 0.5s ease all;
      }

      &:after {
        transform: rotate(0deg);
        transition: 0.5s ease all;
      }
    }

    &:before {
      transform: rotate(180deg);
      transition: 0.5s ease all;
    }

    &:after {
      transform: rotate(180deg);
      transition: 0.5s ease all;
    }
  }
}

// Full screen menu
.noscroll {
  overflow: hidden;
}

.front.noscroll {
  .site-name {
    background: $logo-white-low no-repeat 0 0 / 170px;

    @media (min-width: $mobile) and (max-width: 979px) {
      width: 150px;
      height: 126px;
      background-size: 140px auto;
      background-position: 100% 50%;
    }
  }
}

.logged-in {
  .full-screen-menu {
    @media (min-width: 720px) and (max-width: 979px) {
      padding: 262px 30px 30px;
    }
  }
}

.front .region-user-second .full-screen-menu {
  padding: 194px 15px 30px;
  @media (min-width: 720px) and (max-width: 979px) {
    padding: 176px 30px 30px;
  }
  @media (min-width: 980px) {
    padding: 163px 30px 30px;
  }
}

.not-front.logged-in .region-user-second .full-screen-menu {
  padding: 194px 30px 30px;
  @media (min-width: 720px) and (max-width: 979px) {
    padding: 176px 30px 30px;
  }
}

.region-user-second .full-screen-menu {
  display: none;
  @media (min-width: 720px) {
    // display: block;
  }
  @media (min-width: 720px) and (max-width: 979px) {
    padding: 176px 30px 30px;
    .block-inner {
      .full-screen-menu-parents {
        width: calc((100% / 12 * 5) - 2px);
      }

      .full-screen-menu-children {
        width: calc((100% / 12 * 7) - 2px);
      }
    }
  }
  @media (min-width: 980px) {
    .full-screen-menu-parents {
      width: calc((100% / 12 * 4) - 2px);
    }
    .full-screen-menu-children {
      width: calc((100% / 12 * 5) - 2px);
    }
  }
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  padding: 188px 30px 30px;
  overflow-x: hidden;
  min-height: 100%;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  .block-inner {
    background: $lavender_mist;
    height: inherit;
    overflow-x: hidden;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .fs-menu-title {
      display: block;
      font-size: 1.750rem;
      font-weight: bold;
      margin: 30px 20px;
      min-height: 42px;
      text-align: left;
    }

    .full-screen-menu-parents,
    .full-screen-menu-children {
      border-left: 2px solid $pink_lavender;
      height: inherit;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .menu-explore {
      display: none;
      overflow-x: hidden;
      height: inherit;
      width: calc(100% / 12 * 3);
      float: left;
      @media (min-width: 980px) {
        display: inline-flex;
        flex-direction: column;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .view-display-id-explore {
        .view-empty {
          margin: 0;
        }

        @include custom-scrollbar;
        overflow-x: auto;
        margin: 0 0 0 20px;
        padding-right: 20px;

        .views-row {
          a.menu-explore-link {
            display: block;
            padding: 10px;
            margin-bottom: 20px;
            text-align: left;

            .field-image {
              margin-bottom: 14px;

              img {
                max-width: 100%;
                height: auto;
              }
            }

            .field-title {
              margin-bottom: 14px;
              color: $jet;
              font-size: 1.375rem;
              font-weight: bold;
              line-height: 1.36;
            }

            .field-intro {
              color: $jet;
              line-height: 1.44;
              font-weight: normal;
            }

            &:hover {
              .field-title {
                color: $rose_madder;
              }
            }
          }
        }
      }
    }

    .full-screen-menu-parents {
      display: inline-flex;
      flex-direction: column;
      float: left;

      .menu-block-wrapper.menu-block-6 {
        // 1st level
        li {
          a {
            border-top: 2px solid $lavender_mist;

            &.active-trail.active {
              color: $rose_madder;
            }

            &:hover {
              background: $pink_lavender;
              border-top: 2px solid $pink_lavender;
            }

          }

          &.has-children {
            span.expand {
              @include plus-icons;
            }
          }

          &.active-trail a {
            color: $rose_madder;
          }

          .children-expanded ~ a {
            background-color: $pink_lavender;
            border-top: 2px solid $pink_lavender;
          }
        }
      }
    }

    .full-screen-menu-children {
      display: inline-flex;
      flex-direction: column;
      float: left;

      //Menu styles
      .menu-block-wrapper.menu-block-7 {
        // 2nd level
        ul > li > ul > li {
          & > a {
            border-top: 2px solid $pink_lavender;
            font-weight: normal;

            &:hover {
              background-color: $tyrian_purple;
              color: white;

              & ~ .expand {
                &:before,
                &:after {
                  background-color: white;
                }
              }

              &.active {
                color: white;
              }
            }

            &.open {
              background-color: $pink_lavender;
            }

            &.active {
              color: $rose_madder;
            }

          }

          &.last {
            a {
              border-bottom: 2px solid $pink_lavender;
            }
          }

          &.expanded {
            & > .expand {
              @include plus-icons;
            }
          }

          // 3rd level
          & > ul {
            padding: 11px 0;
            background: $anti_flash_white;

            & > li {
              & > a {
                background: $anti_flash_white;
                border: none;
                font-size: 1.125rem;
                padding: 6px 60px 6px 40px;

                &:hover {
                  background: $pink_lavender;
                  color: $jet;
                }
              }

              &.last {
                a {
                  border-bottom: none;
                }
              }

              &.expanded {
                & > .expand {
                  @include plus-icons;
                  height: 37px;
                }
              }

              // 4th level
              & > ul {
                background: white;

                & > li {
                  & > a {
                    background: white;
                    padding: 6px 70px;
                  }

                  // &.expanded {
                  //   .expand {
                  //     @include plus-icons;
                  //     height: 37px;
                  //   }
                  // }
                  & > ul {
                    border-bottom: 2px solid $pink_lavender;

                    & > li {
                      & > a {
                        // background: pink;
                        padding: 5px 100px;
                      }

                      .expand {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      //menu functionality (hide parents)
      .menu-block-wrapper.menu-block-7 {
        & > ul.menu > li {
          display: none;

          &.show-children {
            display: block;

            & > ul {
              display: block;
            }
          }

          & > ul {
            display: none;

            li > ul {
              display: none;
            }
          }
        }

        & > ul.menu > li > {
          a,
          .expand-wrapper {
            visibility: hidden;
            height: 0;
            width: 0;
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .menu-block-wrapper.menu-block-6,
    .menu-block-wrapper.menu-block-7 {
      float: left;
      @include custom-scrollbar;
      overflow-x: auto;
      min-height: auto !important;
      max-height: calc(100% - 42px);
      width: 100%;

      li {
        width: 100%;
        position: relative;

        a {
          padding: 16px 60px 16px 20px;
          font-size: 1.250rem;
          font-weight: bold;
          color: $jet;
          margin: 0;
        }

        &.has-children {
          a {
          }
        }
      }

      & > li {
        float: left;
        clear: both;
        width: 50%;
        position: relative;
      }
    }
  }
}

// Menu trigger
.block.menu-trigger-block {
  display: none;
  @media (min-width: 740px) {
    display: block;
  }
  @media (min-width: $mobile) and (max-width: 980px) {
    position: absolute;
    right: 0;
    bottom: 68px;
    z-index: 1;
    .block-inner {
      .main-menu-trigger {
        color: $jet;

        .menu-icon {
          .main-menu-btn-icon,
          .main-menu-btn-icon:before,
          .main-menu-btn-icon:after {
            background-color: $jet;
          }
        }

        &:hover {
          .menu-icon {
            .main-menu-btn-icon,
            .main-menu-btn-icon:before,
            .main-menu-btn-icon:after {
              background-color: white;
            }
          }
        }

        &.close:hover {
          .menu-icon {
            .main-menu-btn-icon,
            .main-menu-btn-icon:before,
            .main-menu-btn-icon:after {
              background-color: white
            }
          }
        }
      }
    }
  }

  .main-menu-trigger {
    cursor: pointer;
    color: $jet;
    display: block;
    font-size: 1.125rem;
    font-weight: 400;
    margin-right: 30px;
    background: transparent no-repeat 50% 10px / 20px 20px;
    padding: 18px 16px 36px;
    width: 47px;
    @media (min-width: 980px) {
      padding: 18px 16px 42px;
      margin-right: 1em;
    }

    .menu-icon {
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 22px;

      &:focus-visible {
        outline-offset: 2px !important;
      }

      .main-menu-btn-icon {
        position: absolute;
        top: 50%;
        display: block;
        height: 3px;
        width: 30px;
        background-color: $jet;
        // transition: all .25s;

        &:before {
          position: absolute;
          content: "";
          display: block;
          top: -13px;
          left: 0;
          height: 3px;
          width: 30px;
          background-color: $jet;
          // transition: all .25s;
        }

        &:after {
          position: absolute;
          content: "";
          display: block;
          top: 13px;
          left: 0;
          height: 3px;
          width: 30px;
          background-color: $jet;
          // transition: all .25s;
        }
      }
    }

    .menu-title {
      &:after {
        content: "Menu";
        display: block;
      }
    }

    &:hover {
      background: $tyrian_purple;
      color: white;

      .main-menu-btn-icon {
        background-color: white;
        transition: width .25s;

        &:before {
          background-color: white;
          transition: all .25s;
        }

        &:after {
          background-color: white;
          transition: all .25s;
        }
      }
    }

    &.close {
      background: $lavender_mist;
      color: $tyrian_purple;

      .menu-icon {
        .main-menu-btn-icon {
          background-color: $tyrian_purple;
          // transform: rotate(45deg);
          height: 0;
          width: 0;

          &:before {
            top: 0;
            background-color: $tyrian_purple;
            transform: rotate(45deg);
          }

          &:after {
            top: 0;
            background-color: $tyrian_purple;
            transform: rotate(-45deg);
          }
        }
      }

      .menu-title {
        &:after {
          content: "Close";
        }
      }

      &:hover {
        background-color: $tyrian_purple;
        color: white;

        .menu-icon {
          .main-menu-btn-icon {
            &:before {
              background-color: white;
            }

            &:after {
              background-color: white;
            }
          }
        }
      }
    }
  }
}

.front .menu-trigger-block {
  @media (min-width: $mobile) and (max-width: 739px) {
    display: block;
  }

  .main-menu-trigger {
    color: white;

    @media (min-width: $mobile) and (max-width: 739px) {
      margin-right: 15px;
    }

    .menu-icon {
      .main-menu-btn-icon {
        background-color: white;

        &:before {
          background-color: white;
        }

        &:after {
          background-color: white;
        }
      }
    }

    &:hover {
      background: $tyrian_purple;
    }

    &.close {
      background: $lavender_mist;
      color: $tyrian_purple;

      .menu-icon {
        .main-menu-btn-icon {
          background-color: $tyrian_purple;
          // transform: rotate(45deg);
          height: 0;
          width: 0;

          &:before {
            top: 0;
            background-color: $tyrian_purple;
            transform: rotate(45deg);
          }

          &:after {
            top: 0;
            background-color: $tyrian_purple;
            transform: rotate(-45deg);
          }
        }
      }

      .menu-title {
        &:after {
          content: "Close";
        }
      }
    }
  }
}

#block-menu-block-4 {
  @media (min-width: 740px) {
    display: none;
  }

  .dl-menuwrapper {
    button {
      background-color: white;
      height: 106px;
      width: 79px;

      &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 3px;
        border-radius: 0;
        background: $jet;
        top: 20px;
        left: 24px;
        box-shadow: 0 13px 0 $jet, 0 26px 0 $jet;
      }

      &:hover {
        background: $tyrian_purple;

        &:after {
          background: white;
          box-shadow: 0 13px 0 white, 0 26px 0 white;
        }

        .menu-title {
          color: white;
        }
      }

      .menu-title {
        text-indent: 0;
        display: block;
        font-size: 1.125rem;
        font-weight: 400;
        font-family: "Lato", "Open Sans", sans-serif;
        text-shadow: none;
        margin-top: 7px;

        &:after {
          content: "Menu";
        }
      }

      &.dl-active {
        background: $lavender_mist;

        &:after {
          background: $tyrian_purple;
          box-shadow: none;
          transform: rotate(45deg);
          top: 35px;
        }

        &:before {
          content: '';
          position: absolute;
          width: 30px;
          height: 3px;
          border-radius: 0;
          background: $tyrian_purple;
          top: 35px;
          left: 24px;
          transform: rotate(-45deg);
        }

        .menu-title {
          color: $tyrian_purple;

          &:after {
            content: "Close";
          }
        }
      }
    }

    .dl-menu {
      top: 106px;
      background-color: $pink_lavender;
      width: calc(100% - 8px);
      right: 0;
    }

    li {
      background: $lavender_mist;
      margin: 0 0 1px;

      a {
        color: $jet;
        background: $grey-plus scroll no-repeat 95% 50% transparent;
        font-size: 1rem;
        font-weight: bold;

        &:only-child {
          background: none;
        }

        &:hover {
          background-color: $pink_lavender;
        }
      }

      &.dl-back {
        background-color: $light_gray;

        a {
          color: $jet;
          background: $arrow-grey-left scroll no-repeat 20px 50% transparent;
          padding: 19px 20px 15px 50px;
          text-transform: capitalize;
        }
      }

      &.dl-subviewopen {
        ul.dl-submenu {
          li:nth-child(2) {
            background: $pink_lavender;

            a {
              background: $arrow-grey-right scroll no-repeat 95% 50% / 18px 18px transparent;
              font-size: 1rem;
              font-weight: bold;
            }
          }
        }
      }

      & > ul {
        & > li {
          a {
            background-size: 12px;
            font-size: 0.750rem;
            font-weight: normal;
          }
        }
      }
    }
  }
}

.front {
  #block-menu-block-4 {
    .dl-menuwrapper {
      button {
        top: -82px;
      }

      .dl-menu {
        top: 44px;
      }
    }
  }
}

body.noscroll {
  #zone-header-2-wrapper {
    display: none;
  }

  #block-views-front-links {
    z-index: 0;
  }
}
