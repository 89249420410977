.view-projects-feed,
.view-collaborations,
.view-centres-and-units,
.view-people,
.view-page-courses {
  &.filter-block {
    .views-exposed-form .views-exposed-widget {
      width: 100%;
      @media (min-width: 740px) {
        width: 48.7%;
      }
      @media (min-width: 980px) {
        width: 23.5%;
      }

      .views-widget {
        width: 100%;

        .form-item {
          width: 100%;

          input[type="search"],
          input[type="text"],
          select {
            width: 100%;
          }
        }
      }
    }

    @media (min-width: 740px) and (max-width: 979px) {
      .views-exposed-widgets .views-exposed-widget:nth-child(even) {
        padding-right: 0;
      }
    }
    @media (min-width: 980px) {
      .views-exposed-widgets .views-exposed-widget:nth-child(4) {
        padding-right: 0;
      }
    }

    //Submit/reset buttons
    .views-exposed-widget.views-submit-button {
      padding-right: 0;
    }

    button.form-reset,
    input[type="submit"] {
      margin-bottom: 8px;
      width: 100%;
      @media (min-width: 540px) {
        width: 48%;
        margin-right: 12px;
        margin-bottom: 0;
      }
      @media (min-width: 680px) {
        width: 49%;
      }
      @media (min-width: 740px) {
        width: 48%;
      }
      @media (min-width: 980px) {
        width: 47%;
      }
    }
  }
}

//Search block with even number of filters
.view-projects-feed {
  &.filter-block {
    .views-exposed-widget.views-submit-button {
      @media (min-width: 740px) {
        float: right;
      }
      @media (min-width: 980px) {
        padding-right: 5px;
      }
      @media (min-width: 1220px) {
        padding-right: 15px;
      }
    }
  }
}

.view-people.filter-block {
  .views-exposed-form {
    .views-exposed-widget-submit {
      width: 100%;
      @media (min-width: 740px) {
        width: 48.7%;
      }
      @media (min-width: 980px) {
        width: 23.8%;
      }
    }

    .views-exposed-widget {
      &.views-reset-button,
      &.views-submit-button {
        width: 100%;
        @media (min-width: 540px) {
          width: 48%;
        }
        @media (min-width: 740px) {
          width: 48%;
        }
        @media (min-width: 980px) {
          width: 45.5%;
        }

        input#edit-reset,
        input[type="submit"] {
          width: 100%;
        }

        input#edit-reset {
          @media (min-width: 540px) {
            margin-left: 12px;
          }
        }
      }
    }
  }
}
