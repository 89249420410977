.front {
  .zone-preface-3-wrapper {
    background-color: $jet;

    .grid-12.region-preface-third {
      margin: 0;

      .region-inner {
        text-align: center;
      }
    }

    .grid-12.region-preface-fourth {
      float: none;
      display: block;
      position: relative;
      margin: 0 auto;
    }

    .zone.container-12 {
      width: 100%;
      position: relative;
    }

    .block-hero-cx-block {
      background: $lavender_mist;
      padding: 30px 0 30px 30px;
    }

    .hero-image {
      width: 100%;
      line-height: 0;
      max-height: 850px;
      overflow: hidden;
      @media (min-width: $normal) {
        height: 490px // 700px;
      }
      @media (min-width: $wide) {
        max-width: none;
        height: 720px;
      }

      picture {
        position: relative;
        width: 100%;
      }

      img {
        position: relative;
        width: 100%;
        height: auto;
        line-height: 0;
        overflow: hidden;
        text-align: center;
      }

      @media (min-width: $wide) {
        max-width: none;
        //width: auto;
      }
      @media (min-width: $wide) and (max-width: 1600px) {
        img {
          width: auto;
          //height: 100%;
          width: 1600px;
          position: relative;
          margin: 0 auto;
        }
      }
    }

    .hero-content {
      color: white;
      background: rgba($dark_scarlet, 0.9);
      background-blend-mode: multiply;
      padding: 20px 20px 30px;
      margin: 0;
      float: left;
      width: 90%;
      margin-top: -40px;
      text-align: left;
      @media (min-width: 540px) {
        width: 75%;
        margin-top: -70px;
      }
      @media (min-width: 740px) {
        width: 50%;
        margin-top: -150px;
      }

      .hero-title {
        font-size: 1.75em;
        line-height: 1.363636363636364;
        margin-bottom: 20px;
      }

      .hero-text {
        font: 1.25em/1.461538461538462 "Lato";
      }
    }

    @media (max-width: $normal-down) {
      .zone.container-12 {
        .container-12 {
          width: 100%;
          margin-left: -30px;
        }
      }
    }

    @media (min-width: $normal) {
      background-color: $jet;
      position: relative;
      .grid-12.region-preface-third {
        margin: 0;
        overflow-y: hidden;
      }
      .block-hero-cx-block {
        background: none;
        padding: 0;
      }
      .hero-image {
        position: absolute;
      }
      .hero-content {
        color: white;
        background: transparent $sail-tyrian no-repeat -440px 0% / 1160px 1294px;
        opacity: 1;
        padding: 48px 60px 413px;
        height: 100%;
        //margin: -290px 0 -38px -243px;
        //margin: -290px 0 -38px -173px;
        margin: -290px 0 -258px -173px;
        margin: -290px 0 -350px -173px;
        width: 100%;
        // padding: 47px 60px 330px;
        // margin: -290px 0 -280px -173px;
        //float: none;
        .hero-title {
          font-size: 2.5em;
          line-height: 1.363636363636364;
          margin-bottom: 20px;
        }

        .hero-text {
          font: 1.625em/1.461538461538462 "Lato";
        }

        .hero-text-wrapper {
          margin-top: 470px;
          max-width: 470px;
          margin-left: 140px;
        }
      }
    }

    @media (min-width: $wide) {
      .hero-content {
        padding: 48px 60px 404px;
        margin: -290px 0 -135px -173px;
        //margin: -290px 0 -185px -173px;
        .hero-title {
          font-size: 2.75em;
          line-height: 1.363636363636364;
          margin-bottom: 20px;
        }

        .hero-text-wrapper {
          margin-top: 480px;
          //margin-top: 530px;
          max-width: 470px;
          margin-left: 140px;
        }
      }
    }
  }

  #section-content {
    position: relative;
    @media(max-width: 1000px) {
      top: 0 !important;
    }
  }
}


// COURSE SEARCH BLOCK

.block-wrapper,
.block-link-wrapper {
  //margin-bottom:30px;
  margin: 30px 20px;
  box-sizing: border-box;
  @media (min-width: $normal) {
    display: inline-block;
  }
}

@media (min-width: $normal) {

  .block-link-wrapper {
    min-width: 600px;

    @media (min-width: $wide) {
      min-width: auto;
    }
  }
}

.search-block-wrapper {
  @media (min-width: $mobile-lg) {
    max-width: 80%;
    position: relative;
    margin: 20px auto 0; //0 auto;
  }
  @media (min-width: $narrow) {
    max-width: none;
    margin: 0 auto;
  }

  .search-wrapper.filter-block {
    .views-exposed-widgets {
      margin: 0;
    }

    @media (min-width: $narrow) {
      .views-exposed-form {
        vertical-align: middle;
      }
    }

    .views-exposed-widget {
      padding: 0;
      float: none;
      display: inline-block;
      @media (min-width: $narrow) {
        vertical-align: bottom;
      }

      &.views-submit-button {
        float: none;
        display: inline-block;
        //vertical-align: text-bottom;
        margin-top: 44px;
        margin-left: -4px;
        @media (min-width: $mobile-lg) {
          margin-left: -20px;
        }
        @media (min-width: $narrow) {
          margin-top: 10px;
          margin-left: -2px;
        }
      }
    }

    .views-exposed-widget-submit {
      display: inline-block;
    }

    .search-title-wrapper {
      width: calc(100% - 50px);
      @media (min-width: $narrow) {
        width: auto;
      }

      .views-widget {
        width: 100%;
        @media (min-width: $narrow) {
          width: auto;
          .form-item-title,
          input[type="text"] {
            width: 100%;
            width: 280px;
            margin-top: 10px;
          }
        }

        .form-item-title {
          width: auto;
        }
      }
    }

    input[type="text"] {
      height: 48px;
      width: 100%;
    }

    input[type="submit"] {
      background: $ruby_red $arrow-white-right no-repeat scroll 50% 50% / 18px 14px;
      text-indent: -9999px;
      height: 48px;
      width: 50px;
      margin: 0;

      &:hover {
        background: $lavender_mist $arrow-rose-right no-repeat scroll 50% 50% / 18px 14px;
      }
    }

    input#edit-keywords {
      padding-left: 20px;
    }

    label {
      position: initial;
      margin-bottom: 10px;
      color: white;
      display: block;
      font-size: 1.875em;
      margin-right: 20px;
      width: calc(100% + 50px);
      @media (min-width: $narrow) {
        display: inline;
        vertical-align: text-top;
      }
    }
  }
}

#block-views-front-links {
  position: relative;
}

.link-wrapper {
  @media(min-width: 640px) { //$mobile-lg
    .views-row {
      display: flex;
    }
  }

  .button-class {
    margin-top: 20px;
    width: 100%;
    z-index: 500;

    &.download {
      margin-right: 20px;

      span.ext {
        display: none;
      }
    }

    a {
      width: calc(100% - 40px);
    }

    @media(min-width: 640px) { //$mobile-lg
      margin-top: 0;
      width: auto;
      width: calc(50% - 12px);
      a {
        width: 100%;
        box-sizing: border-box;
      }
    }

    @media (min-width: $wide) {
      width: auto;
      min-width: 230px;
    }
  }
}
