// /* COLOURS */ //

$rose_madder: #E31735; // /* LSTM red */
$tyrian_purple: #730732; // /* LSTM burgandy */
$jet: #343434; // /* Charcoal grey */
$bright_cerulean: #28B4E4; // /* LSTM bright blue */
$sonic_silver: #787878; // /* mid-grey */
$trolly-grey: #818181; // /* tag grey */
$lavender_mist: #E5E6EF; // /* light grey */
$dark_scarlet: #4D0017; // /* darker version of the lstm burgandy to use instead of blend modes */
$sapphire_blue: #0062A1; /* mid-blue */
$burgundy: #770031; /* lstm burgandy */
$shauss_pink: #FFA3B0; /* mid-pink */
$pink_lavender: #CEB9C9; /* pink lavender */
$anti_flash_white: #F1F3F7; /* anti flash white */
$light_gray: #D2D3DB; /* light gray */
$sandy_brown: #E49C51; /* sandy brown */
$spanish_orange: #E46809; /* spanish orange */
$middle_blue_green: #90D1CF; /* middle blue green */
$lime_green: #18c709; /* lime green */


// ACCESSIBILITY ADDITIONS
$ruby_red: #960F23;
$davys_grey: #565656;


// BORDERS
$outline-border: 2px solid $lime_green !important;
$outline-offset-border: -2px;
