#sliding-popup.sliding-popup-bottom,
#sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner {
  background: $sapphire_blue;

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.eu-cookie-withdraw-tab {
  background: $sapphire_blue;
  text-shadow: none;
  top: 0;
  border: 2px solid white;
}

#sliding-popup {
  .popup-content #popup-text {
    max-width: 100%;
  }

  .popup-content #popup-text p {
    display: block;
  }

  .popup-content #popup-text p,
  .popup-content #popup-text button,
  .eu-cookie-compliance-category {
    font-size: 14px;
    font-weight: normal;
    text-shadow: none;
  }

  .eu-cookie-compliance-category {
    padding-bottom: 10px;

    input {
      margin-bottom: 3px;
    }
  }
}

