.filter-block,
.node-webform {
  .view-filters {
    background: #770031;
    color: #FFFFFF;
    margin: 1em 0 10px;
    padding: 5px 30px;
  }

  .views-exposed-form label:not(.option) {
    position: absolute;
    left: -9999px;
  }

  .views-widget {
    display: inline-block;
    vertical-align: middle;
  }

  .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 0;
  }

  .views-exposed-form .views-exposed-widget #edit-reset,
  .views-exposed-form .views-exposed-widget .form-reset {
    margin-right: 0; /* new */
  }

  button,
  input[type="reset"],
  input[type="submit"],
  input[type="button"] {
    background: $rose_madder;
    border: none;
    border-radius: 0;
    color: #FFFFFF;
    font: 700 1.125em/1.1 "Lato", sans-serif;
    margin-right: 1em;
    padding: 0.7em 1em;
    text-shadow: none;
    width: auto;
  }

  button:hover,
  input[type="reset"]:hover,
  input[type="submit"]:hover,
  input[type="button"]:hover {
    background: #FFFFFF;
    color: $rose_madder;
  }

  textarea, select, input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"] {
    background-color: #F9FAFC;
    border: none;
    height: 44px;
    padding: 6px;
    font: 400 18px/20px "Lato", sans-serif;
    color: $jet;
  }

  textarea {
    height: auto;
  }

  input[type="search"],
  input[type="text"] {
    width: 100%;
  }

  .selectric {
    min-height: 44px;
  }

  .selectric .label {
    color: $jet;
    font-size: 16px;
  }

  .selectric-items li {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #fff;
    color: $jet;
    cursor: pointer;
    display: block;
    padding: 3px 5px;
    font-size: 14px;
  }

  .selectric-items li:hover {
    background-color: #e5e6ef;
  }

  .selectric-items li.selected {
  }

  .selectric .button:after {
    color: #000000;
    border-color: #000000 transparent -moz-use-text-color;
  }

  #edit-field-research-theme-target-id-wrapper .selectric-items,
  #edit-theme-wrapper .selectric-items,
  #edit-field-course-type-tid-wrapper .selectric-items,
  #edit-field-seminar-lecture-series-tid-wrapper .selectric-items {
    width: 160px !important;
  }
}

form {
  input[type="text"] {
    height: 44px;
    font-size: 16px;
  }
}

.node-webform {
  .webform-progressbar {
    display: none;
  }

  textarea, select, input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"] {
    border: 1px solid darken(#F9FAFC, 7%);
    padding: 3px 8px 6px;
    font-size: 1em;
    line-height: 1.25;

    &:hover {
      border: 1px solid darken(#F9FAFC, 10%);
    }
  }

  textarea,
  select[multiple="multiple"] {
    height: auto;
  }

  select {
    padding: 6px 20px 6px 8px;
  }

  input[type="search"],
  input[type="text"] {
    width: auto;
  }

  @media (max-width: $narrow) {
    input[type="search"],
    input[type="text"],
    input[type="email"],
    select,
    textarea {
      max-width: 100%;
    }
  }

  button:hover,
  input[type="reset"]:hover,
  input[type="submit"]:hover,
  input[type="button"]:hover {
    background: $tyrian_purple;
    color: #FFFFFF;
  }

  .webform-component:not(.webform-container-inline) label {
    margin-bottom: 10px;
  }

  .form-item:not(.form-type-radio):not(.form-type-checkbox) {
    margin-bottom: 40px
  }

  .webform-component:not(.webform-component-time) {
    .form-checkboxes .form-item,
    .form-radios .form-item {
      width: 100%;
      display: inline-block;

      margin: 5px 10px 5px 0;
      box-sizing: border-box;
      padding: 6px;
      border: 1px solid darken(#F9FAFC, 7%);
      background-color: #F9FAFC;

      @media (min-width: $narrow) {
        min-width: 200px;
        width: calc(24% - 10px);
      }
    }
  }

  .webform-grid {
    thead th, th {
      background: lighten(#e5e6ef, 5%); //darken(#F9FAFC,10%);
    }

    tr.odd, tr.odd td, tr:hover td {
      background: #F9FAFC;
    }

    tr.even, tr.even td {
      background: darken(#F9FAFC, 2%);
    }

    tr.odd:hover td, tr.odd:hover, tr.even:hover td, tr.even:hover {
      background: darken(#F9FAFC, 4%);
    }
  }

  fieldset {
    padding: 20px;
    border: 1px solid $lavender_mist;
    margin-bottom: 30px;

    :last-child {
      margin-bottom: 0;
    }

    .fieldset-legend {
      background: $tyrian_purple;
      color: white;
      padding: 8px 15px;
      font-size: 18px;

      a {
        color: white !important;
      }
    }

    &.collapsible {
      .fieldset-legend {
        background: $rose_madder !important;
        color: white !important;
        padding: 8px 15px;
        font-size: 18px;

        &:hover {
          background: $tyrian_purple !important;
        }
      }
    }
  }
}
