// Also known as panels?

.tag-wrapper {
  margin-bottom: 20px;

  .panel-date {
    float: none;
    width: 100%;
    text-align: right;
    display: inline-block;
  }

  .tag-type + .panel-date {
    float: right;
    width: auto;
  }
}

.tag-type {
  display: inline;
  color: #730732;
  background-color: white;
  padding: 3px 4px;
  border: 3px solid #F1E6EA;
  text-transform: uppercase;
  font-size: 14px;
}

.tag-date {
  display: inline;
  color: #730732;
  background-color: #F1E6EA;
  padding: 6px 10px;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: -3px;
}

.small-tag {
  font-size: 0.75em;
  color: $trolly-grey;
  margin-top: 0.5em;
}

.feed-slider .small-tag {
  margin-top: 0.5em;
}

.panel-title {
  color: $jet;
  font-size: 22px;
}

@media (min-width: $narrow) and (max-width: $normal-down) {
  .grid-3 .panel-title {
    font-size: 16px;
  }
}

.panel-padding-20 {
  padding: 20px;
}

.panel-padding-10 {
  padding: 10px;
}

a:hover .panel-title {
  color: $rose_madder;
}

.row-title {
  font-weight: bold;
}

.panel-person .panel-title {
  text-align: center;
}
