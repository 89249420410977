
.alert-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  background-image: $promo-pattern-light;
  background-color: $tyrian_purple;

  &:hover {
    background-color: $jet;

    .alert-text {
      p {
        margin-bottom: 0;
      }
    }

    .alert-icon {
      background-color: $tyrian_purple;
    }

    .alert-link {
      background: $tyrian_purple;
      color: white;
    }
  }

  @media (max-width: $narrow) {
    flex-direction: column;
    .alert-icon {
      width: 100%;
      justify-content: center;
    }
  }
  // $mobile-lg
  .alert-icon {
    margin-right: auto;
    background-image: $promo-pattern-light;
    background-color: $ruby_red;
    border-right: 2px solid white;
    display: flex;
    align-items: center;

    span {
      background: $ico-alert no-repeat 50% 50% / 42px 40px;
      display: block;
      height: 40px;
      width: 42px;
      padding: 12px;
    }
  }

  .alert-text {
    color: white;
    padding: 16px 40px;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: right;
  }

  .alert-link {
    background: $ruby_red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    min-width: 201px;

    span {
      padding: 18px 30px;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;

      &:after {
        background: $arrow-white-right no-repeat scroll 0% 50% / 18px 14px;
        width: 18px;
        height: 14px;
        content: "";
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
}

//PAGE TOP COVID ALERT
.view-id-front.view-display-id-alert_top,
.view-id-front.view-display-id-alert {
  margin-right: 20px;

  .alert-wrapper {
    .alert-icon {
      span {
        background: $ico-alert-125 no-repeat 50% 50% / 42px 40px;
        display: block;
        height: 40px;
        width: 42px;
        padding: 12px;
      }
    }
  }
}

//PAGE TOP 2ND ALERT
.view-id-front.view-display-id-alert_top {
  .alert-wrapper {
    .alert-icon.top {
      span {
        background: url('../img/svg/book-regular.svg') no-repeat 50% 50% / 42px 40px;
      }
    }
  }
}

// PAGE BOTTOM BLM ALERT
.view-id-front.view-display-id-alert_bottom {
  margin-top: 3em !important;

  .alert-wrapper {
    .alert-icon {
      //background-image: $promo-pattern-light;
      background-color: $jet;

      span {
        background: $ico-alert-blm no-repeat 50% 50% / 42px 40px;
        display: block;
        height: 40px;
        width: 42px;
        padding: 12px;
      }
    }

    &:hover {
      .alert-icon {
        background-color: $tyrian_purple;
      }
    }
  }
}

.page-node-9346.node-type-flexi-landing-page {
  .alert-wrapper {
    .alert-icon {
      span {
        background: url('../img/svg/ico-alert-lung.svg') no-repeat 50% 50% / 42px 40px;
      }
    }
  }
}
