.feed-slider {
  .views-row.slide {
    margin-right: 20px;

    &.people {
      margin-right: 0;

      .panel-title {
        font-size: 1.125em;
      }

      .panel-text {
        color: black;
      }
    }
  }
}

.node-type-project #zone-content-wrapper {
  display: block !important;
}

.flex-nav-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.not-front {
  .flexslider-block.flexslider {
    ul.slides {
      li {
        margin-right: 10px;
      }

      li.views-row-first {
        margin-left: 10px;
      }
    }

    ul.flex-direction-nav {
      position: absolute;
      top: 0;
      //top:-88px;
      @media (min-width: 1380px) {
        top: 0;
      }

      li a {
        display: block;
        height: 60px;
        width: 60px;
        font-size: 0;
      }

      li.flex-nav-next a {
        background: $rose_madder $arrow-white-next no-repeat 50% 50% / 24px 24px;
        right: 0;

        &:hover {
          background: white $arrow-rose-next no-repeat 50% 50% / 24px 24px;
        }

        @media (min-width: 740px) {
          top: -90px;
        }
        @media (min-width: 1380px) {
          top: 0;
          right: -60px;
        }
      }

      li.flex-nav-prev a {
        background: $rose_madder $arrow-white-prev no-repeat 50% 50% / 24px 24px;
        left: 0;

        &:hover {
          background: white $arrow-rose-prev no-repeat 50% 50% / 24px 24px;
        }

        @media (min-width: 740px) {
          top: -88px;
        }
        @media (min-width: 1380px) {
          top: 0;
          left: -60px;
        }
      }
    }
  }
}

.flexslider-block-title {
  position: relative;

  h2.block-title span {
    margin-left: 100px;
    @media (min-width: 1380px) {
      margin-left: 0px;
    }
  }
}

.view-id-research.view-display-id-block_2 {
  .flexslider {
    .flex-direction-nav {
      .flex-nav-prev {
        a {
          left: 0;
        }
      }

      .flex-nav-next {
        a {
          right: 0;
        }
      }
    }
  }
}
