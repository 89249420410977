.node-type-flexi-landing-page {
  .view-sections .box-links-section ul {
    padding: 20px;
    display: grid;
    align-items: end;

    // mobile
    grid-template-columns: 100%;

    // tablet
    @media screen and (min-width: 768px) {
      grid-template-columns: 50% 50%;
    }

    // desktop
    @media screen and (min-width: 992px) {
      grid-template-columns: 33% 33% 33%;
    }

    li > a {
      width: 100%;
    }

    li.last a {
      margin-bottom: 0;
    }
  }

  &.fundraising-flexible-page {

    #zone-preface-2-wrapper {

      // HEADER
      .section-row {
        &.header-section {
          .header-content {
            @media (min-width: 740px) {
              bottom: calc(50% - 180px);
              width: 400px;
            }

            p {
              &:last-child {
                margin-bottom: 0 !important;
              }
            }

            .header-promo {
              background-color: $tyrian_purple;
              color: white;
              padding: 30px 50px;

              @media (min-width: 740px) {
                padding: 50px;
              }

              &:before {
                content: "";
                background: url('../img/svg/sail-pattern.svg') repeat 50% / 40px;
                opacity: 14%;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                filter: saturate(0);
              }

              h1 {
                font-size: 2.750rem;
                margin-bottom: 20px;
              }

              .header-description {
                font-style: normal;

                h2 {
                  font-size: 1.875rem;
                  line-height: 2.500rem;
                  margin-bottom: 20px;
                }

                p {
                  font-size: 1.375rem;
                  line-height: 2rem;
                }
              }
            }
          }
        }
      }
    }


    #zone-postscript-1 {
      &.container-12 {
        width: 100% !important;

        .region-postscript-first {
          margin-left: 0;
          margin-right: 0;

          .block-fundraising-curated {

            .content {
              margin-left: 10px;
              margin-right: 10px;

              @media (min-width: 740px) {
                width: 720px;
                margin-left: auto;
                margin-right: auto;
              }

              @media (min-width: 980px) {
                width: 960px;
              }

              @media (min-width: 1220px) {
                width: 1200px;
              }
            }

          }

          .section-row {
            padding: 0;
            margin: 0 0 50px;

            p {
              &:last-child {
                margin-bottom: 0 !important;
              }
            }

            .section-inner {
              margin-left: 10px;
              margin-right: 10px;

              @media (min-width: 740px) {
                width: 720px;
                margin-left: auto;
                margin-right: auto;
              }

              @media (min-width: 980px) {
                width: 960px;
              }

              @media (min-width: 1220px) {
                width: 1200px;
              }
            }


            // BIG BOX LINKS
            &.big-box-link-section {
              @media (min-width: 740px) {
                .section-inner {
                  background-color: transparent !important;

                  .big-box-link-intro {
                    @media (min-width: 740px) {
                      width: calc(360px + 150px) !important;

                      background-color: white;
                      padding-left: 180px;
                      margin-left: -150px;
                      order: 2;
                    }

                    @media (min-width: 980px) {
                      width: calc(480px + 150px) !important;
                    }

                    @media (min-width: 1220px) {
                      width: calc(600px + 150px) !important;
                    }

                    a {
                      position: relative;
                    }
                  }

                  .big-box-link-image {
                    margin-top: 40px;
                  }
                }
              }

              &.row-box-style-orange {
                .section-inner {
                  .big-box-link-intro {
                    background-color: $sandy_brown;
                  }
                }
              }

              &.row-box-style-green {
                .section-inner {
                  .big-box-link-intro {
                    background-color: $middle_blue_green;
                  }
                }
              }

              &.row-box-style-text-top {
                .section-inner {
                  align-items: center;

                  .big-box-link-intro {
                    background-color: white;
                    z-index: 2;
                    padding-left: 32px;
                    border-right: 5px solid $jet;

                    &:before {
                      content: "";
                      background: url('../img/svg/sail-pattern.svg') repeat 50% / 40px;
                      opacity: 4%;
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      filter: saturate(0);
                    }


                    @media (min-width: 740px) {
                      width: calc(((100% / 12) * 5) + 150px) !important;
                    }
                  }

                  .big-box-link-image {
                    margin-top: 0;
                    @media (min-width: 740px) {
                      width: calc((100% / 12) * 7) !important;
                    }
                  }
                }
              }
            }


            // CONTENT BLOCK + ER BOXES
            &.content-block-section {
              background-color: $middle_blue_green;
              @extend .panel-padding-tb-double;
              margin-bottom: 0;
              padding-bottom: 0 !important;
            }

            &.small-box-links-section {
              background-color: $middle_blue_green;
              @extend .panel-padding-tb-double;
              padding-top: 0;

              ul {
                @media (min-width: 740px) {
                  display: flex;
                  flex-flow: wrap;
                }

                li {
                  float: none !important;

                  .panel-padding {
                    padding: 1.250rem;
                    text-align: left;

                    &.panel-title {
                      //font-size: 22px;
                      //font-weight: normal;
                    }

                    &.panel-summary {
                      padding-top: 0;
                    }
                  }
                }
              }
            }


            //BLOCKQUOTE
            &.blockquote-box-link-section {
              background-color: $sandy_brown;
              padding: 0;

              .section-inner {
                align-items: center;

                .panel-padding-medium {
                  border-left: 5px solid $spanish_orange;
                  background-color: transparent;

                  margin: 40px 20px;
                  padding: 0 0 0 20px;

                  @media (min-width: 740px) {
                    order: 0;
                    margin: 60px 0 60px 30px;
                    padding: 0 80px 0 20px;
                    width: 360px !important
                  }

                  @media (min-width: 980px) {
                    min-width: calc(100% - 560px) !important;
                    margin: 40px 0 40px 30px;
                  }

                  blockquote {
                    background: none transparent;
                    padding: 0;
                    margin: 0;

                    &:before,
                    &:after {
                      height: 34px;
                      width: 51px;
                    }

                    &:before {
                      content: open-quote;
                      background: url('../img/svg/quote-open-orange.svg') no-repeat 0 0 / 51px 34px;
                      margin: 0 0 5px;
                      padding: 0;
                    }

                    &:after {
                      content: close-quote;
                      background: url('../img/svg/quote-close-orange.svg') no-repeat 0 0 / 51px 34px;
                      float: right;
                      margin: -15px 0 0;
                      padding: 0;
                    }

                    p {
                      font-size: 24px;
                    }

                    & + p {
                      margin-top: 10px;
                    }
                  }

                  p {
                    font-size: 16px;
                    margin-bottom: 10px;
                  }
                }
              }

              .big-box-link-image {
                margin-top: 0;

                @media (min-width: 740px) {
                  width: 430px;
                }
              }
            }


            // MENU LINKS
            &.menu-links-section {
              background-color: $sandy_brown;
              padding: 0;

              .section-inner {
                background-color: transparent;
                @media (min-width: 740px) {
                  display: block;
                }

                .menu-links-intro {
                  display: block !important;
                  width: 100% !important;
                  float: none;
                  @extend .panel-padding-tb-double;
                  padding-bottom: 1rem !important;

                  .panel-padding-double {
                    padding: 0;
                  }
                }

                .item-list.panel-padding-double {
                  float: none;
                  @extend .panel-padding-tb-double;
                  padding-top: 0 !important;

                  ul {
                    @media (min-width: 740px) {
                      display: flex;
                      flex-grow: 4;
                    }

                    li {
                      flex-grow: 3;
                      flex-basis: 0;

                      @media (min-width: 740px) {
                        margin: 0 5px 2px;
                        &.first {
                          margin-left: 0;
                        }
                        &.last {
                          margin-right: 0;
                        }
                      }

                      a {
                        background-color: $spanish_orange;

                        &:hover {
                          background-color: $jet;
                        }
                      }
                    }
                  }
                }
              }
            }


            // FEEDS
            &.latest-feed-section {
              h2.block-title {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

