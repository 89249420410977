/*   SUBMENU  */
#region-sidebar-first {
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li:not(.active) {
      display: none;
    }

    & > li.active li {
      display: block;
    }

    & > li.active-trail {
      display: block;

      li {
        display: block;
      }
    }

    li {
      list-style: none;
      margin: 0 0 1px;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid $pink_lavender;
      border: none;

      a {
        background: $anti_flash_white;
        color: $tyrian_purple;
        display: block;
        font: 700 0.875em/1.25 "Lato", "Open Sans", sans-serif;
        padding: 0.75em 2em 0.75em 0.75em;
        border-bottom: 1px solid $pink_lavender;

        &.active {
          background: $tyrian_purple;
          color: white;
        }

        &.active-trail {
          background: $pink_lavender;
          color: $tyrian_purple;
        }

        &:hover {
          background-color: $tyrian_purple;
          color: white;
        }
      }

      &.expanded {
        & > a {
          &.active,
          &.active-trail {
            border-bottom: 1px solid $pink_lavender;
          }
        }
      }

      & ul,
      &.active ul ul,
      &.active-trail ul ul {
        display: none;
      }

      &.active ul,
      &.active-trail ul,
      &.active ul li.active ul,
      &.active-trail ul li.active-trail ul {
        display: block;
      }

      &.expanded.active-trail {
        border: none;
      }

      li {
        a {
          background: $anti_flash_white;
          font-weight: normal;
          padding: 0.5em 2.5em 0.5em 1.5em;

          &.active {
            background: $tyrian_purple;
            color: white;

            &:hover {
              background-color: $tyrian_purple;
              color: white;
            }
          }

          &:hover {
            background-color: $pink_lavender;
            color: $tyrian_purple;
          }
        }

        li {
          a {
            font-size: 0.813em;
            padding-left: 3em;
          }

          li {
            a {
              font-size: 0.813em;
              padding-left: 4.5em;
            }

            li {
              a {
                font-size: 0.813em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }
}
