.button-class {
  font-weight: 700;
  font-size: 1.125em;
  display: inline-block;
  text-align: center;

  a,
  a:link,
  a:visited {
    color: white;
    background-color: $ruby_red;
    padding: 10px 20px 11px;
    display: inline-block;
  }

  a:hover,
  a:link:hover,
  a:visited:hover {
    background-color: white;
    color: $rose_madder;
  }

  &.download a {
    &:after {
      background: $download-white no-repeat scroll 0% 50% / 18px 24px;
      width: 18px;
      height: 24px;
      content: "";
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }

    &:hover:after {
      background: $download-rose no-repeat scroll 0% 50% / 18px 24px;
    }
  }

  &.go a {
    &:after {
      background: $arrow-white-right no-repeat scroll 0% 50% / 18px 14px;
      width: 18px;
      height: 14px;
      content: "";
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
    }

    &:hover:after {
      background: $arrow-rose-right no-repeat scroll 0% 50% / 18px 14px;
    }
  }
}

// MORE BUTTON

.body-shorten .more-link {
  //margin:0 0 20px;
  position: absolute;
  bottom: 12px;
  right: 0;

  a {
    background: $rose_madder $arrow-white-down no-repeat 82% 50% / 15px 20px;
    color: white;
    font-size: 1.125em;
    padding: 15px 60px 15px 35px;

    &:hover {
      background: $burgundy $arrow-white-down no-repeat 82% 50% / 15px 20px;
    }

    &.less {
      background: $rose_madder $arrow-white-up no-repeat 82% 50% / 15px 20px;

      &:hover {
        background: $burgundy $arrow-white-up no-repeat 82% 50% / 15px 20px;
      }
    }
  }
}

.node ul li.statistics_counter {
  margin: 0;
  padding-left: 0;
  position: absolute;
  bottom: 15px;
}

.tab-footer {
  .button-class {
    float: right;
  }
}

#twilio-webchat-widget-root button {
  background: $tyrian_purple;
  text-shadow: none;
  color: white;
  box-sizing: border-box;
  span {
    color: white;
  }
}

html {
  *, ::after, ::before {
    box-sizing: revert;
  }
}
