#block-views-authors-contextual-authors:not(.context-block-empty) + #block-views-authors-contextual-text {
  margin-top: -0.625em;

  h2.block-title {
    display: none;
  }

  &.panel-rhs .content {
    padding-top: 0;
  }
}
