
.node-type-collaboration,
.node-type-centre,
.node-type-consultancy-technical-assistance,
.node-type-education-notice,
.node-type-service,
.node-type-seminars-and-lectures,
.node-type-project,
.node-type-news-article,
.node-type-media,
.node-type-impact-story,
.node-type-event,
.node-type-department-research-area,
.node-type-research-department,
.node-type-case-study,
.node-type-page,
.node-type-blog {
  .node .field-name-field-image.field-type-image {
    float: none;
    margin: 0 0 1em 0;

    img {
      max-width: 100% !important;
      width: auto;
    }
  }
}
