.view-id-innovation.view-display-id-block_1 {
  .views-row {
    a.inno-slide {
      display: block;
      position: relative;
      @media (min-width: $normal) {
        &:before {
          content: "";
          background: $slider-bg-left no-repeat 0 0 / auto 675px;
          display: block;
          position: absolute;
          height: 675px;
          width: 675px;
          left: 0;
          top: 0;
        }
      }

      &:after {
        content: "";
        background: $slider-bg-right no-repeat 0 0 / 230px 394px;
        display: block;
        position: absolute;
        height: 394px;
        width: 230px;
        right: 0;
        top: 0;
      }

      @media (min-width: $normal) {
        &:after {
          content: "";
          background: $slider-bg-right no-repeat 0 0 / auto 675px;
          display: block;
          position: absolute;
          height: 675px;
          width: 388px;
          right: 0;
          top: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }

        &:before {
          background: $slider-bg-left-hover no-repeat 0 0 / auto 675px;
        }
      }

      img {
        width: 100%;
        height: auto;
      }

      .slide-text {
        color: white;
        background: #343434;
        position: relative;
        padding: 20px;
        z-index: 1;

        .slide-title {
          font-weight: 400;
          font-size: 26px;
          line-height: 1.3076;
          margin-bottom: 20px;
        }

        .slide-desc {
          font-weight: 300;
          font-size: 16px;
          line-height: 1.4375;
          margin-right: 40px;
        }

        @media (min-width: $normal) {
          background: transparent;
          position: absolute;
          top: 160px;
          width: 34%;
          left: 30px;
          padding: 0;
          .slide-title {
            font-weight: 400;
            font-size: 26px;
            line-height: 1.3076;
            margin-bottom: 20px;
          }
          .slide-desc {
            font-weight: 300;
            font-size: 16px;
            line-height: 1.4375;
            margin-right: 40px;
          }
        }
        @media (min-width: $wide) {
          top: 230px;
        }
      }
    }
  }
}

/* innovation - news/impact story */
.view-id-innovation.related .flexslider ul.slides {
  margin-left: -10px;
}

.view-id-innovation.related .flexslider ul.slides li {
  margin: 0 10px;
}

.view-id-innovation.related .flexslider .flex-direction-nav {
  z-index: 89;
  height: 60px;
}

#block-views-innovation-news,
#block-views-innovation-impact {
  display: none;
  @media (min-width: $narrow) {
    display: block;
  }
}

#block-views-innovation-news-m,
#block-views-innovation-impact-m {
  display: block;
  @media (min-width: $narrow) {
    display: none;
  }

  h2.block-title {
    text-align: center;
    padding: 0 70px;
  }

  .flex-viewport {
    max-width: 300px !important;
    margin: 0 auto;

    .slides {
      margin-left: 0;

      .views-row {
        max-width: 300px !important;
        margin: 0;
      }
    }
  }
}

