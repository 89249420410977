#zone-breadcrumbs-wrapper {
  background: #e6e6f0;
  padding: 1.75em 0 0;
}

#block-delta-blocks-breadcrumb {
  ul {
    margin: 0;
    padding: 0;

    li {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20height%3D%229.2700005%22%0A%20%20%20width%3D%227.9200001%22%0A%20%20%20version%3D%221.1%22%0A%20%20%20viewBox%3D%220%200%207.9200001%209.2700005%22%0A%20%20%20enable-background%3D%22new%200%200%2010%208%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%0A%20%20%20%20%20id%3D%22defs7%22%20%2F%3E%3Ctext%0A%20%20%20%20%20id%3D%22text3408%22%0A%20%20%20%20%20y%3D%2211.37%22%0A%20%20%20%20%20x%3D%22-2.29%22%0A%20%20%20%20%20style%3D%22font-style%3Anormal%3Bfont-variant%3Anormal%3Bfont-weight%3Abold%3Bfont-stretch%3Anormal%3Bfont-size%3A20px%3Bline-height%3A125%25%3Bfont-family%3ALato%3B-inkscape-font-specification%3A'Lato%2C%20Bold'%3Btext-align%3Astart%3Bletter-spacing%3A0px%3Bword-spacing%3A0px%3Bwriting-mode%3Alr-tb%3Btext-anchor%3Astart%3Bfill%3A%23343434%3Bfill-opacity%3A1%3Bstroke%3Anone%3Bstroke-width%3A1px%3Bstroke-linecap%3Abutt%3Bstroke-linejoin%3Amiter%3Bstroke-opacity%3A1%22%0A%20%20%20%20%20xml%3Aspace%3D%22preserve%22%3E%3Ctspan%0A%20%20%20%20%20%20%20y%3D%2211.37%22%0A%20%20%20%20%20%20%20x%3D%22-2.29%22%0A%20%20%20%20%20%20%20id%3D%22tspan3410%22%3E%26gt%3B%3C%2Ftspan%3E%3C%2Ftext%3E%0A%3C%2Fsvg%3E") no-repeat 100% 50%;
      font-weight: 700;
      margin: 0 0.75em 0 0;
      padding: 0 1.125em 0.125em 0;
      color: $davys_grey;

      &.last {
        background: none;
        margin-right: 0;
        padding-right: 0;
      }

      a {
        color: $ruby_red;

        &:hover {
          color: $rose_madder;
        }

        &.active {
          color: $davys_grey;
        }
      }
    }
  }
}

#zone-content #block-delta-blocks-page-title.block {
  margin: 0;
}
