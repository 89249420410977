.grid-8-block {
  .panel:hover .panel-title {
    color: white;
  }

  .panel:hover .panel-details {
    color: white;
    background: $ruby_red; //$rose-madder;
  }

  .panel-details {
    .type {
      width: 100%;
      display: inline-block;
      font-size: 0.875em;
      font-weight: 500;
    }
  }

  .view-footer {
    display: inline-block;
    list-style: none;

    .views-row {
      @media (max-width: $narrow) {
        height: auto !important;
      }
    }

    @media (max-width: 740px) {
      width: 100%;
    }
    @media (min-width: $wide) {
      width: 300px;
    }

    a.grid-8-block-link {
      background-color: $ruby_red; //$rose_madder;
      color: white;
      display: block;
      padding: 20px;
      height: 100%;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;

      &:hover {
        background-color: $jet; //$burgundy;
      }

      .link-title {
        @media (min-width: $narrow) {
          font-size: 1.125em;
          margin-top: 40px;
        }
        @media (min-width: $normal) {
          margin-top: 60px;
          font-size: 1.75em;
        }
        @media (min-width: $wide) {
          font-size: 2.5em;
          margin-top: 70px;
        }
      }

      .link-subtitle {
        @media (min-width: $narrow) {
          font-size: 1em;
        }
        @media (min-width: $normal) {
          font-size: 1.375em;
        }
        @media (min-width: $wide) {
          font-size: 1.675em;
        }
      }
    }
  }
}

// NEWS SECTION FILTERS

.view-news {
  .view-filters form {
    margin-top: 5px;
  }

  &.filter-block {
    input[type="text"] {
      font-size: 16px;
      color: $jet;
      padding-left: 10px;
    }

    select {
      padding: 6px 20px;
    }
  }

  .views-exposed-form .views-exposed-widget {
    padding: 5px 20px 5px 0;

    &.views-submit-button,
    &.views-reset-button {
      float: right;
      padding-right: 0;
      width: 100%;
      @media (min-width: $narrow) {
        padding-right: 10px;
        width: 47.7%;
      }
      @media (min-width: $normal) {
        width: 31.8%;
        padding-right: 0;
      }
      @media (min-width: $wide) {
        width: 32%;
        padding-right: 8px;
      }

      input[type="submit"] {
        width: 48%;
        @media (min-width: $narrow) {
          width: 47%;
        }
        @media (min-width: $normal) {
          width: 46.5%;
        }
      }

      button.form-submit.form-reset {
        background-color: white;
        color: $rose_madder;
        width: 48.3%;
        @media (min-width: $narrow) {
          width: 47%;
        }
        @media (min-width: $normal) {
          width: 46.5%;
        }
        @media (min-width: $wide) {
          width: 47.5%;
        }

        &:hover {
          background-color: $rose_madder;
          color: white;
        }
      }
    }
  }

  #edit-field-search-terms-value-wrapper,
  #edit-theme-wrapper,
  #edit-department-wrapper,
  #edit-research-area-wrapper,
  #edit-centre-wrapper,
  #edit-collaboration-wrapper,
  #edit-project-wrapper {
    width: 100%;

    .form-select {
      width: 100%;
    }

    @media (min-width: $narrow) {
      width: 47.6%;
    }
    @media (min-width: $normal) {
      width: 31.8%;
    }

    input#edit-field-search-terms-value,
    .form-item.form-type-select {
      width: 100%;
    }

    .views-widget {
      width: 100%;
    }
  }

  @media (max-width: 979px) {
    #edit-theme-wrapper,
    #edit-research-area-wrapper,
    #edit-collaboration-wrapper {
      padding-right: 0;
    }
  }
  @media (min-width: $normal) {
    #edit-department-wrapper,
    #edit-collaboration-wrapper {
      padding-right: 0;
    }
  }
}

.rhs-project {
  .row-img img {
    max-width: 100%;
    height: auto;
  }
}

.block-research-contextual-topics {
  display: none;
}


.rhs-logo {
  margin-bottom: 0 !important;

  ul li {
    margin: 0;
  }

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.view.related {
  .panel-summary {
    display: none;
  }
}

.view-publications {
  table {
    tr {
      @media(max-width: 540px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
