$promo-bg-default: $sapphire_blue;
$promo-bg-default-hover: $bright_cerulean;
$promo-text-colour: white;
$promo-text-colour-hover: $jet;
$promo-desc-text: 500 1em / 1.461538 'Open Sans', sans-serif;
$promo-title-text: bold 1.5em / 1.2 'lato', serif;

#zone-promo-1,
#zone-promo-2 {
  &.container-12 {
    width: 100%;

    .grid-12 {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .view-promo {
      .edit-link {
        background-color: $rose_madder;

        a {
          color: white;
        }

        &:hover {
          background-color: $burgundy;
        }
      }
    }
  }
}

.front .block .content .promo-wrapper .views-row,
.promo-wrapper .views-row {
  overflow: hidden;
  position: relative;
  margin: 0;

  a.panel {
    overflow: hidden;
    //max-height:675px;
    background-color: $promo-bg-default;
    color: $promo-text-colour;
    min-height: 520px;
    position: relative;
    @media (min-width: 740px) {
      min-height: 360px;
    }

    &:after {
      content: "";
      display: block;
      background: $promo-pattern-light repeat scroll 50% 50% / 713px 360px;
      height: 520px;
      width: 2000px;
      top: 0;
      position: absolute;
    }

    .promo-inner-wrapper {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      text-align: right;
      @media (min-width: 740px) {
        position: relative;
      }

      .promo-background {
        display: block;
        position: absolute;
        width: 715px;
        right: 5%;
        @media (min-width: 740px) and (max-width: 979px) {
          right: 50%;
        }
        @media (min-width: 980px) and (max-width: 1099px) {
          right: 44%;
        }
        @media (min-width: 1100px) {
          right: 40%;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          background: $promo-sail-sapphire no-repeat scroll 50% 50% / 715px 360px;
          height: 360px;
          width: 715px;
        }
      }

      .promo-text-wrapper {
        display: inline-block;
        background-color: $promo-bg-default;
        color: $promo-text-colour;
        padding: 25px 20px 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: left;
        width: 100%;
        @media (min-width: 740px) {
          padding: 0;
          top: 50px;
          width: 340px;
          width: 400px;
          right: 20px;
          left: unset;
          //position:relative;
          background-color: transparent;
        }

        .promo-title {
          font: $promo-title-text;
          margin-bottom: 20px;
          @media (min-width: 740px) {
            font-size: 2.5em;
          }
        }

        .promo-description {
          font: $promo-desc-text;
          margin-bottom: 20px;
          @media (min-width: 740px) {
            font-size: 1.625em;
          }
        }

        .promo-arrow {
          background: $arrow-white-right no-repeat scroll 50% 50% / 26px 20px;
          display: inline-block;
          height: 20px;
          margin: 0;
          padding: 0;
          width: 26px;
        }
      }
    }
  }
}

// HOVER
.front .block .content .promo-wrapper .views-row,
.promo-wrapper .views-row {
  a.panel:hover {
    //color:$jet;
    background-color: $promo-bg-default-hover;

    &:after {
      background: $promo-pattern-dark repeat scroll 50% 50% / 713px 360px;
    }

    .promo-inner-wrapper {
      .promo-background:after {
        background: $promo-sail-cerulean no-repeat scroll 50% 50% / 715px 360px;
      }

      .promo-text-wrapper {
        background-color: $promo-bg-default-hover;
        color: $promo-text-colour-hover;

        .promo-arrow {
          background: $arrow-jet-right no-repeat scroll 50% 50% / 26px 20px;
        }
      }
    }
  }
}
