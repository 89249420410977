.views-exposed-widget {
  #autocomplete {
    background: #EFEFEF;
    border: 1px solid #C4C4C4;
    box-shadow: 0 0 10px -6px;
    width: 198px !important;

    ul, li {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 12px;
      line-height: 20px;
      min-height: 20px;
    }

    ul {
      //width: calc(100% - 2px);
      li {
        display: block;
        padding: 8px;
        border-bottom: 1px solid #eee;
        border-top: 1px solid #fff;
        color: #343434;
        cursor: pointer;
        display: block;
        padding: 3px 5px;
        font-size: 14px;
        line-height: 20px;
        min-height: 20px;
        background: #EFEFEF;

        &:hover {
          background: #E5E6EF;
          color: #444;
        }
      }
    }
  }
}

.view.view-people.view-id-people {
  display: flex;
  flex-direction: column;

  .filter-people.filter-block {
    order: 1;

    .panel {
      padding: 5px calc(25px - 1em) 5px 25px;
    }

    @media (min-width: 1220px) {
      .views-exposed-widget {
        .selectric .label {
          margin: 0 35px 0 6px;
        }
      }
    }

    .form-item-edit-person-type-61,
    .form-item-edit-field-person-type-tid-61 {
      display: none;
    }

    .views-exposed-widget-submit {
      //float: left;
      float: right;
      clear: left;

      .views-exposed-form .views-exposed-widget {
        float: none;
        display: inline-block;

        &.views-reset-button {
          padding-right: 0;
        }
      }
    }
  }

  .view-header {
    order: 2;
    background: none;
    margin: 0 10px;

    .m-20 {
      margin: 20px 0;
    }

    .results-total {
      color: #343434;
    }
  }

  .view-content {
    order: 3;
  }

  #views_infinite_scroll-ajax-loader {
    order: 4;
  }

  & > .item-list {
    order: 5;
  }
}
