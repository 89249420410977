.view-sections {
  .panel-video {
    margin-bottom: 1rem;
    float: none;
    width: 100%;

    &.vid-right {
      float: right;
      width: 60%;
      margin-left: 1rem;
    }

    &.vid-left {
      float: left;
      width: 60%;
      margin-right: 1rem;
    }
  }

  .view-id-videos {
    .view-content {
      display: flex;
      flex-wrap: wrap;

      .views-row {
        margin: 10px;
        max-width: 380px;

        a.colorbox-load {
          display: block;

          &:focus {
            border: none;
          }

          .video-image {
            position: relative;

            &:before {
              background: linear-gradient(0deg, rgba(52, 52, 52, 0.4), rgba(52, 52, 52, 0.4));
              content: "";
              height: 100%;
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }

            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              height: 52px;
              width: 32px;
              background: $video-play-white no-repeat;
              left: 50%;
              margin-left: -16px;
              top: 50%;
              margin-top: -26px;
            }
          }

          .video-quote {
            background: #F1F3F7;
            padding: 20px 30px 12px;

            .video-quote-text {
              font-size: 16px;
              line-height: 1.4375;
              color: #343434;
              position: relative;
              margin-bottom: 10px;

              &:before {
                background: $quote-before no-repeat 0 0 / 24px 25px;
                content: "";
                height: 25px;
                width: 24px;
                position: absolute;
                left: -36px;
                top: 0;
              }
            }

            .video-quote-author {
              font-style: italic;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #730732;
            }
          }

          &:hover {
            .video-image:before {
              display: none;
            }

            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
