// 3 reasons to study
h2.three-reasons-title .large-number {
  font-size: 80px;
  font-weight: 800;
  line-height: 1.2;
  color: $tyrian_purple;
}

.field-collection-item-field-three-reasons {
  .content {
    @media (min-width: $normal) {
      display: flex;
    }

    .field {
      border: 2px solid $pink_lavender;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 5px;
      padding: 20px;

      .field-item {
        &::before {
          display: block;
          width: 100%;
          position: relative;
          font-weight: 900;
          font-size: 30px;
          line-height: 1.2;
          color: $tyrian_purple;
          margin-bottom: 10px;
        }
      }
    }

    .field-name-field-reason-1 .field-item::before {
      content: "1";
    }

    .field-name-field-reason-2 .field-item::before {
      content: "2";
    }

    .field-name-field-reason-3 .field-item::before {
      content: "3";
    }
  }
}

// Tabs
.node-type-course {
  .horizontal-tabs {
    ul.horizontal-tabs-list {
      border-bottom: 2px solid $rose_madder;

      li {
        a {
          text-transform: initial;
        }
      }
    }
  }
}

// Promo RHS
.node-type-course {
  .group-details {
    .field-name-field-promo-rhs {
      margin-bottom: 0 !important;
      padding: 0 !important;

      a.promo-link {
        background: #007281;
        color: white;

        .promo-text {
          padding: 32px 30px 30px;
          position: relative;
          z-index: 1;

          .promo-title {
            font-size: 26px;
            line-height: 1.3076;
            font-weight: bold;
            margin-bottom: 30px;
          }

          .promo-date {
            font-size: 22px;
            font-weight: 500;
            line-height: 1.4545;
            margin-bottom: 10px;
          }

          .promo-body {
            line-height: 1.4375;
            font-weight: 400;
          }

          &::before {
            content: "";
            display: block;
            background: $promo-bg-left no-repeat 0% 50%;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
          }
        }

        .promo-link-title {
          font-size: 18px;
          line-height: 1.2222;
          font-weight: 600;
          padding: 12px;
          background: #74C5C3;
          color: #343434;
          text-align: center;

          &:after {
            content: "";
            display: inline-block;
            background: $promo-arrow-right no-repeat 50% 50%;
            width: 18px;
            height: 18px;
            right: 0;
            margin-left: 10px;
            vertical-align: sub;
          }
        }

        &:hover {
          background: #0062A1;

          .promo-link-title {
            background: #28B4E4;
          }
        }
      }
    }
  }

  .panel-rhs.panel-grey {
    background: white;
  }
}

.node-type-course {
  .collapsible {
    h2 a.field-group-format-title {
      color: white;
      pointer-events: none;
    }
  }
}
