.search-page {
  .view-content {
    .views-row {
      margin: 0 0 20px;

      &.views-row-odd {
        // margin-right: 0;
        @media (min-width: $narrow) {
          margin-right: 20px;
          width: calc(100% / 2);
        }
      }

      a.centres-views-link,
      a.collaborations-views-link {
        display: block;

        span {
          display: block;
          margin: 0;
        }

        &:hover {
          background-color: #999999;
          background-color: #e52945;

          .content-image {
            background-color: #000000;
            background-color: #FFFFFF;
            background-color: #fc0000;
            background-color: #e52945;

            img {
              opacity: 0.6;
              opacity: 0.7;
            }
          }

          .content-title {
            color: #FFFFFF;
          }

          .content-text {
            color: #FFFFFF;
          }
        }

        .content-title {
          font-size: 1.125em;
          font-weight: 700;
          padding: 0.75em 20px 0.25em;
          color: #333333;
        }

        .content-text {
          padding: 0 20px;
          // margin-bottom:20px;
          color: #333333;
        }

        .content-text-wrapper {
          margin-left: 0;
          padding-bottom: 20px;
          @media (min-width: $narrow) {
            margin-left: 100px;
            padding-bottom: 0;
          }
          @media (min-width: $normal) {
            margin-left: 140px;
          }
          @media (min-width: $wide) {
            margin-left: 180px;
          }
        }
      }
    }
  }
}

.filter-block {
  &.display-label {
    .views-exposed-form {
      label {
        display: block;
      }

      .views-exposed-widget {
        width: 100%;

        select {
          width: 100%;
        }

        @media (min-width: $narrow) {
          width: 20%;
        }

        .views-widget {
          width: 100%;
        }

        &.views-submit-button {
          width: auto;
          margin-top: 25px;
        }
      }
    }
  }
}

#edit-field-search-terms-value::-webkit-input-placeholder,
#edit-field-search-terms-value:-ms-input-placeholder,
#edit-field-search-terms-value::-moz-placeholder,
#edit-field-search-terms-value:-moz-placeholder {
  color: $jet;
}

.view-courses-landing-page .views-exposed-form {
  label {
    position: initial !important;
  }

  .views-exposed-widget.views-submit-button {
    padding-top: 32px;
  }
}
