.section-header {
  position: relative;
  z-index: 99;
}

.site-name {
  width: 200px;
  height: 156px;
  background: $logo no-repeat 100% 50% / 189px; // 190px;
  display: block;
  float: right;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-indent: -9999px;

  @media (min-width: $mobile) and (max-width: 979px) {
    width: 150px;
    height: 126px;
    background-size: 140px;
  }

  a {
    display: block;
    height: 100%;
  }
}


/*****   FRONT PAGE   *****/
.front {
  .site-name {
    background-image: $logo-125;
    background-size: 200px;
    width: 200px;
    height: 106px;

    @media (min-width: $mobile) and (max-width: 979px) {
      height: 126px;
      background-size: 200px;
      width: 210px;
    }

    @media (min-width: $normal) {
      background-image: $logo-white-125;
    }
    @media (min-width: 1024px) {
      background-size: 210px;
      width: 210px;
      margin-left: -20px;
    }
  }

  .menu-trigger-block {
    @media (min-width: 740px) and (max-width: 980px) {
      bottom: -136px;
    }
  }

  //@media (min-width: 740px) and (max-width: 979px) {
  @media (min-width: $mobile) and (max-width: 979px) {
    &.noscroll .site-name {
      background-image: $logo-white-125;
      width: 150px;
      height: 126px;
      background-size: 140px auto;
      background-position: 100% 50%;
    }
  }
}


/*****   NOT FRONT PAGES WITH 125YEAS LOGO   *****/
.not-front {
  &.page-node-8504.node-type-flexi-landing-page,
  &.page-node-9282.node-type-flexi-landing-page {
    .site-name {
      background-image: $logo-125;
      background-size: 200px;
      width: 200px;
      height: 106px;

      @media (min-width: $normal) {
        background-image: $logo-125;
      }
      @media (min-width: 1024px) {
        background-size: 210px;
        width: 210px;
        margin-left: -20px;
      }
    }

    .menu-trigger-block {
      @media (min-width: 740px) and (max-width: 980px) {
        bottom: -136px;
      }
    }

    .zone-branding .zone-branding-inner .region-user-second {
      padding-top: 0;
      @media (min-width: 980px) {
        padding-top: 26px;
      }
    }
  }
}


@media (min-width: 740px) {
  .not-front.noscroll {
    .site-name {
      background-image: $logo-white-low;
      background-size: 190px;

      @media (min-width: $mobile) and (max-width: 979px) {
        background-size: 140px;
      }
    }

    .region-user-second .content ul li a {
      &.research,
      &.research:hover {
        background-image: $ico-research-white;
        color: white;
      }

      &.clinical,
      &.clinical:hover {
        background-image: $ico-clinical-white;
        color: white;
      }

      &.study,
      &.study:hover {
        background-image: $ico-study-white;
        color: white;
      }

      &.my-lstm,
      &.my-lstm:hover {
        background-image: $ico-my-lstm-white;
        color: white;
      }

      &.careers,
      &.careers:hover {
        background-image: $ico-careers-white;
        color: white;
      }

      &.search-toggle,
      &.search-toggle:hover {
        background-image: $ico-search-white;
        color: white;
      }
    }

    .region-user-second .content ul li.active-trail {
      a {
        &.research,
        &.research:hover {
          background-image: $ico-research-rose;
          color: $rose_madder;
        }

        &.clinical,
        &.clinical:hover {
          background-image: $ico-clinical-rose;
          color: $rose_madder;
        }

        &.study,
        &.study:hover {
          background-image: $ico-study-rose;
          color: $rose_madder;
        }

        &.my-lstm,
        &.my-lstm:hover {
          background-image: $ico-my-lstm-rose;
          color: $rose_madder;
        }

        &.careers,
        &.careers:hover {
          background-image: $ico-careers-rose;
          color: $rose_madder;
        }

        &.search-toggle,
        &.search-toggle:hover {
          background-image: $ico-search-rose;
          color: $rose_madder;
        }
      }
    }
  }
}

.not-front {
  @media(max-width: 980px) {
    .region-user-second .content ul li a {
      &.research,
      &.research:hover {
        background-image: $ico-research-white;
        color: white;
      }

      &.clinical,
      &.clinical:hover {
        background-image: $ico-clinical-white;
        color: white;
      }

      &.study,
      &.study:hover {
        background-image: $ico-study-white;
        color: white;
      }

      &.my-lstm,
      &.my-lstm:hover {
        background-image: $ico-my-lstm-white;
        color: white;
      }

      &.careers,
      &.careers:hover {
        background-image: $ico-careers-white;
        color: white;
      }

      &.search-toggle,
      &.search-toggle:hover {
        background-image: $ico-search-white;
        color: white;
      }
    }
  }
}

#block-tb-megamenu-main-menu {
  float: right;
}

//.zone-branding-inner.grid-12 {
//  @media(max-width: 979px) {
//    display: flex;
//    flex-direction: column-reverse;
//  }
//}

#zone-branding {
  @media (min-width: $mobile) and (max-width: $normal-down) {
    &.container-12 {
      width: 100%;

      .zone-branding-inner.grid-12 {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;

        .region {
          width: 100%;
          margin: 0;
          padding-left: 5px;
          padding-right: 13px;
          box-sizing: border-box;
        }
      }
    }
  }

  .region.region-branding {
    float: left;
    margin: 0.5em 10px 0.75em;
    //background: white;

  }

  .logo-img a {
    display: block;

    img {
      height: auto;
      width: 75%;
      min-width: 180px;
    }
  }
}

.not-front #zone-branding .region.region-branding {
  margin: 1em 0 1em;
}

/*****   TOP MENU   *****/
.not-logged-in {
  &.front .zone-branding .zone-branding-inner .region-user-second {
    padding-top: 0;
  }

  &.not-front .zone-branding .zone-branding-inner .region-user-second {
    padding-top: 0;
    @media (min-width: 980px) {
      padding-top: 76px;
    }
  }
}

.zone-branding .zone-branding-inner .region-user-second {
  width: auto;
}

.region-user-second {
  background-color: $jet;
  line-height: 0;
  @media (min-width: $normal) {
    background-color: transparent;
  }
  box-sizing: border-box;
  padding: 22px 22px 0;

  .block {
    display: inline-block;
    @media (min-width: $normal) {
      float: left;
    }
  }

  @media (max-width: $wide-down) {
    .region-user-second-inner {
      text-align: right;
    }
  }

  .content {
    ul {
      list-style: outside none none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        list-style: outside none none;
        margin: 0;
        padding: 0;

        a {
          color: white;
          display: block;
          font-size: 0.625em;
          font-weight: 400;
          line-height: 1.4;
          margin-right: 1px;
          background: transparent no-repeat 50% 10px / 20px 20px;
          padding: 36px 10px 18px;
          position: relative;
          @media (min-width: $narrow) and (max-width: $normal-down) {
            background-position: 10px 50%;
            background-size: 25px 25px;
            padding: 18px 10px 18px 46px;
            margin-right: 40px;
          }
          @media (min-width: $normal) {
            background-size: 30px 30px;
            background-position: 50% 20px;
            font-size: 1.125em;
            line-height: 1.555555555555556;
            color: $jet;
            padding: 56px 10px 28px;
            margin-right: 1em;
            &.study,
            &.research,
            &.careers,
            &.clinical {
              width: 75px;
              text-align: center;
              margin-right: 0;
            }
            &.my-lstm {
              width: 77px;
              text-align: center;
            }
          }

          &.active,
          &.active-trail {
            color: $rose-madder;
          }

          &:hover {
            background-color: $tyrian_purple;
            color: white;
          }

          &.research {
            background-image: $ico-research-jet;

            &:hover {
              background-image: $ico-research-white;
            }
          }

          &.clinical {
            background-image: $ico-clinical-jet;

            &:hover {
              background-image: $ico-clinical-white;
            }
          }

          &.study {
            background-image: $ico-study-jet;

            &:hover {
              background-image: $ico-study-white;
            }
          }

          &.my-lstm {
            background-image: $ico-my-lstm-jet;

            &:hover {
              background-image: $ico-my-lstm-white;
            }
          }

          &.careers {
            background-image: $ico-careers-jet;

            &:hover {
              background-image: $ico-careers-white;
            }
          }
        }

        &.active a,
        &.active-trail a {
          background-color: transparent;
          color: $rose-madder;

          &.research {
            background-image: $ico-research-rose;

            &:hover {
              background-image: $ico-research-rose;
            }
          }

          &.clinical {
            background-image: $ico-clinical-rose;

            &:hover {
              background-image: $ico-clinical-rose;
            }
          }

          &.study {
            background-image: $ico-study-rose;

            &:hover {
              background-image: $ico-study-rose;
            }
          }

          &.my-lstm {
            background-image: $ico-my-lstm-rose;

            &:hover {
              background-image: $ico-my-lstm-rose;
            }
          }

          &.careers {
            background-image: $ico-careers-rose;

            &:hover {
              background-image: $ico-careers-rose;
            }
          }
        }
      }
    }

    .search-toggle-wrapper {
      list-style: outside none none;
      margin: 0;
      padding: 0;
      display: inline-block;

      a {
        color: white;
        display: block;
        font-size: 0.625em;
        font-weight: 400;
        line-height: 1.4;
        margin-right: 1px;
        background: transparent no-repeat 50% 10px / 20px 20px;
        padding: 36px 10px 18px;
        position: relative;

        @media (min-width: $narrow) and (max-width: $normal-down) {
          background-position: 10px 50%;
          background-size: 25px 25px;
          padding: 18px 10px 18px 46px;
          margin-right: 40px;
        }

        @media (min-width: $normal) {
          background-size: 30px 30px;
          background-position: 50% 20px;
          font-size: 1.125em;
          line-height: 1.555555555555556;
          color: $jet;
          padding: 56px 10px 28px;
          margin-right: 1em;
        }

        &.active,
        &.active-trail {
          color: $rose-madder;
        }

        &:hover {
          background-color: $tyrian_purple;
          color: white;
        }

        &.search-toggle {
          background-image: $ico-search-white;
          cursor: pointer;

          @media (min-width: $normal) {
            background-image: $ico-search-jet;
          }

          &:hover {
            background-image: $ico-search-white;
          }

          &.close {
            background-color: $lavender_mist;
            background-image: $ico-close-tyrian;
            color: $tyrian_purple;

            &:hover {
              background-color: $tyrian_purple;
              background-image: $ico-close-white;
            }

            @media (min-width: 980px) {
              background-image: $ico-search-jet;
              cursor: pointer;
              &:hover {
                background-image: $ico-search-white;
              }
              &.close {
                background-color: $lavender_mist;
                background-image: none !important;
                color: $tyrian_purple !important;

                &:before,
                &:after {
                  content: "";
                  display: block;
                  width: 30px;
                  height: 3px;
                  background-color: $tyrian_purple;
                  position: absolute;
                  top: 34px;
                  right: 20px;
                  transform: rotate(45deg);
                }

                &:after {
                  transform: rotate(-45deg);
                }

                &:hover {
                  background-color: $tyrian_purple;
                  background-image: none;
                  color: white !important;

                  &:before,
                  &:after {
                    background-color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.front {
  @media (min-width: $normal) {
    .region-user-second {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  .region-user-second .block {
    .content {
      ul {
        li {
          a {
            color: white;
            position: relative;

            &.research,
            &.research:hover {
              background-image: $ico-research-white;
            }

            &.clinical,
            &.clinical:hover {
              background-image: $ico-clinical-white;
            }

            &.study,
            &.study:hover {
              background-image: $ico-study-white;
            }

            &.my-lstm,
            &.my-lstm:hover {
              background-image: $ico-my-lstm-white;
            }

            &.careers,
            &.careers:hover {
              background-image: $ico-careers-white;
            }

            &.search-toggle,
            &.search-toggle:hover {
              background-image: $ico-search-white;
            }
          }
        }
      }

      .search-toggle-wrapper {
        a {
          color: white;
          position: relative;

          &.search-toggle {
            background-image: $ico-search-white;
            cursor: pointer;

            &:hover {
              background-image: $ico-search-white;
            }

            &.close {
              background-color: $lavender_mist;
              background-image: $ico-close-tyrian;
              color: $tyrian_purple;

              &:hover {
                background-color: $tyrian_purple;
                background-image: $ico-close-white;
                color: white;
              }

              @media (min-width: 980px) {
                background-color: $lavender_mist;
                background-image: none;
                color: $tyrian_purple;
                &:before,
                &:after {
                  content: "";
                  display: block;
                  width: 30px;
                  height: 3px;
                  background-color: $tyrian_purple;
                  position: absolute;
                  top: 34px;
                  right: 24px;
                  transform: rotate(45deg);
                }
                &:after {
                  transform: rotate(-45deg);
                }
                &:hover {
                  background-color: $tyrian_purple;
                  background-image: none;
                  color: white;

                  &:before,
                  &:after {
                    background-color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


/*****   SOCIAL TOP   *****/
#block-views-social-links-top {
  display: none;
}


/*****   SEARCH-TOP   *****/
#zone-branding {
  .block-search-form,
  #block-views-exp-search-api-search-api {
    display: none;
    background-color: $lavender_mist;
    clear: both;
    width: 100%;
    position: absolute;
    z-index: 1000;
    bottom: -84px;
    right: 0;
    width: 100%;

    @media (min-width: 520px) {
      width: 520px;
    }

    .block-inner {
      padding: 10px;
      // position: absolute;
      // top: 135px;
      //top: 82px;
      // top: 76px;
    }

    form {
      background: white;
      padding: 10px 20px;
      overflow: hidden;
    }

    input.form-text {
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
      border: none;
      border-bottom: 2px solid $jet;
      color: $jet;
      float: left;
      font: 400 1.25em/1 "Lato", "Open Sans", sans-serif;
      padding: 0;
      width: 90%;
    }

    ::-webkit-input-placeholder {
      color: $jet;
    }

    :-moz-placeholder { /* Firefox 18- */
      color: $jet;
    }

    ::-moz-placeholder { /* Firefox 19+ */
      color: $jet;
    }

    :-ms-input-placeholder {
      color: $jet;
    }

    input.form-submit {
      background: $ico-search-jet no-repeat 0 0;
      border: medium none;
      border-radius: 0;
      float: right;
      height: 20px;
      margin: 8px 0 0 5px;
      overflow: hidden;
      padding: 10px 5px;
      text-indent: -999em;
      width: 20px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  #block-views-exp-search-api-search-api .views-exposed-widget {
    margin: 0;
    padding: 0;
    float: none;
  }
}