/*****   FOOTER   *****/
#section-footer #block-block-1,
#section-footer #block-views-social-links-block h2.block-title {
  display: none;
}

#section-footer #block-block-1 {
  margin-bottom: 2em;
}

#block-views-footer-logos {
  clear: both;
  text-align: center;
  padding-top: 2em;
  border-top: 1px solid #ceced0;
}

#block-views-footer-logos .footer-logo {
  display: inline-block;
  margin: 0 3em 1em;
}

/*****   FOOTER - MAP   *****/
#region-footer-first a {
  display: block;
}

#region-footer-first a.spamspan.mailto {
  display: inline-block;
  margin-left: 5px;
}

#region-footer-first a:hover .find-us-arrow {
  /*background:url("data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%200%200%2042%2042%22%0A%20%20%20viewBox%3D%220%200%2042%2042%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cpath%0A%20%20%20%20%20id%3D%22arrow-sq-up-maroon%22%0A%20%20%20%20%20d%3D%22M0%2C0v42h42V0H0z%20M28.7%2C21.4c-0.6%2C0.6-1.4%2C0.4-1.9-0.1l-4.4-5.4v13.9c0%2C0.8-0.6%2C1.4-1.4%2C1.4s-1.4-0.6-1.4-1.4%20%20V15.9l-4.4%2C5.2c-0.6%2C0.6-1.4%2C0.7-1.9%2C0.1c-0.7-0.4-0.7-1.4-0.1-1.9l6.9-8.1c0.3-0.3%2C0.6-0.4%2C1-0.4s0.8%2C0.3%2C1.1%2C0.6l6.9%2C8.1%20%20c0.1%2C0.1%2C0.3%2C0.6%2C0.3%2C0.8C29.3%2C20.7%2C29.1%2C21%2C28.7%2C21.4z%22%0A%20%20%20%20%20fill%3D%22%23770031%22%20%2F%3E%3C%2Fsvg%3E") no-repeat scroll 0 0 transparent !important;*/
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%200%200%2042%2042%22%0A%20%20%20viewBox%3D%220%200%2042%2042%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cpath%0A%20%20%20%20%20id%3D%22arrow-sq-up-maroon%22%0A%20%20%20%20%20d%3D%22M0%2C0v42h42V0H0z%20M28.7%2C21.4c-0.6%2C0.6-1.4%2C0.4-1.9-0.1l-4.4-5.4v13.9c0%2C0.8-0.6%2C1.4-1.4%2C1.4s-1.4-0.6-1.4-1.4%20%20V15.9l-4.4%2C5.2c-0.6%2C0.6-1.4%2C0.7-1.9%2C0.1c-0.7-0.4-0.7-1.4-0.1-1.9l6.9-8.1c0.3-0.3%2C0.6-0.4%2C1-0.4s0.8%2C0.3%2C1.1%2C0.6l6.9%2C8.1%20%20c0.1%2C0.1%2C0.3%2C0.6%2C0.3%2C0.8C29.3%2C20.7%2C29.1%2C21%2C28.7%2C21.4z%22%0A%20%20%20%20%20fill%3D%22%23343434%22%20%2F%3E%3C%2Fsvg%3E") no-repeat scroll 0 0 transparent !important;
}

#region-footer-first a:hover .find-us-text {
  background-color: $jet; //#730732;
}

#region-footer-first .find-us-map {
  /*background: url("../img/find-us-map.jpg") no-repeat scroll 50% 100% transparent;*/
  display: block;
  height: 260px;
  width: 200px;
  overflow: hidden;
}

#region-footer-first .find-us-map {
}

#region-footer-first .find-us-map iframe {
  margin-top: -150px;
  width: 100%;
  height: 200%;
}

#region-footer-first .find-us-arrow {
  /*background: url("../img/arrows-square.png") no-repeat scroll 0 -62px transparent;*/
  /*background:url("data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%200%200%2042%2042%22%0A%20%20%20viewBox%3D%220%200%2042%2042%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cpath%0A%20%20%20%20%20id%3D%22arrow-sq-up-red%22%0A%20%20%20%20%20d%3D%22M0%2C0v42h42V0H0z%20M28.7%2C21.4c-0.6%2C0.6-1.4%2C0.4-1.9-0.1l-4.4-5.4v13.9c0%2C0.8-0.6%2C1.4-1.4%2C1.4s-1.4-0.6-1.4-1.4%20%20V15.9l-4.4%2C5.2c-0.6%2C0.6-1.4%2C0.7-1.9%2C0.1c-0.7-0.4-0.7-1.4-0.1-1.9l6.9-8.1c0.3-0.3%2C0.6-0.4%2C1-0.4s0.8%2C0.3%2C1.1%2C0.6l6.9%2C8.1%20%20c0.1%2C0.1%2C0.3%2C0.6%2C0.3%2C0.8C29.3%2C20.7%2C29.1%2C21%2C28.7%2C21.4z%22%0A%20%20%20%20%20fill%3D%22%23E31735%22%20%2F%3E%3C%2Fsvg%3E") no-repeat scroll 0 0 transparent !important;*/
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%200%200%2042%2042%22%0A%20%20%20viewBox%3D%220%200%2042%2042%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cpath%0A%20%20%20%20%20id%3D%22arrow-sq-up-red%22%0A%20%20%20%20%20d%3D%22M0%2C0v42h42V0H0z%20M28.7%2C21.4c-0.6%2C0.6-1.4%2C0.4-1.9-0.1l-4.4-5.4v13.9c0%2C0.8-0.6%2C1.4-1.4%2C1.4s-1.4-0.6-1.4-1.4%20%20V15.9l-4.4%2C5.2c-0.6%2C0.6-1.4%2C0.7-1.9%2C0.1c-0.7-0.4-0.7-1.4-0.1-1.9l6.9-8.1c0.3-0.3%2C0.6-0.4%2C1-0.4s0.8%2C0.3%2C1.1%2C0.6l6.9%2C8.1%20%20c0.1%2C0.1%2C0.3%2C0.6%2C0.3%2C0.8C29.3%2C20.7%2C29.1%2C21%2C28.7%2C21.4z%22%0A%20%20%20%20%20fill%3D%22%23960F23%22%20%2F%3E%3C%2Fsvg%3E") no-repeat scroll 0 0 transparent !important;
  display: block;
  height: 42px;
  width: 42px;
}

#region-footer-first .find-us-text {
  background-color: #e31735;
  color: #ffffff;
  display: block;
  padding: 10px;
  width: 180px;
}

#region-footer-first .find-us-box {
  text-align: center;
}

.find-us-map + a {
  position: absolute;
  bottom: 0;
}

/*****   FOOTER - CONTACT   *****/
/*****   #block-views-footer-contact-us,
#block-views-social-links-block {
  margin-left: 30px;
}*/
/*****   FOOTER - SOCIAL   *****/
#block-views-social-links-block {
  float: left;
  display: block;
  clear: inherit;

  .views-row {
    float: left;


    span {
      background-color: #e5e6ef;
      display: block;
      padding: 0.938em 0 0.938em 1.250em;

      a {
        display: block;
        width: 20px;
        height: 20px;
        /*background:url("../img/icons-social-top.png") no-repeat scroll 0 0 transparent;*/
        text-indent: -999em;
        overflow: hidden;
        line-height: 1;
      }

      &.ext {
        display: none;
      }
    }

    &.views-row-last span {
      padding: 0.938em 1.250em;
    }

  }
}

.social-Facebook a {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-260%20402%2040%2040%22%0A%20%20%20viewBox%3D%22-260%20402%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20%20%20d%3D%22M-220%2C408.7c0-3.5-3.2-6.7-6.7-6.7h-26.7c-3.5%2C0-6.7%2C3.2-6.7%2C6.7v26.7%20%20%20c0%2C3.5%2C3.2%2C6.7%2C6.7%2C6.7h13.3v-15.1h-4.9v-6.7h4.9v-2.6c0-4.5%2C3.4-8.5%2C7.5-8.5h5.4v6.7h-5.4c-0.6%2C0-1.3%2C0.7-1.3%2C1.8v2.7h6.7v6.7%20%20%20h-6.7V442h7.1c3.5%2C0%2C6.7-3.2%2C6.7-6.7V408.7z%22%0A%20%20%20%20%20%20%20fill%3D%22%23343434%22%0A%20%20%20%20%20%20%20id%3D%22Facebook%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 0 0 transparent;
  background-size: 20px 20px;
}

.social-Twitter a {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-275%20387%2040%2040%22%0A%20%20%20viewBox%3D%22-275%20387%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20d%3D%22M-235%2C395.5c-1.5%2C0.6-3.1%2C1-4.7%2C1.2c1.7-0.9%2C3-2.4%2C3.6-4.2%20%20c-1.6%2C0.9-3.3%2C1.5-5.2%2C1.9c-1.5-1.5-3.6-2.4-6-2.4c-4.5%2C0-8.2%2C3.4-8.2%2C7.6c0%2C0.6%2C0.1%2C1.2%2C0.2%2C1.7c-6.8-0.3-12.8-3.3-16.9-8%20%20c-0.7%2C1.1-1.1%2C2.4-1.1%2C3.8c0%2C2.6%2C1.4%2C5%2C3.7%2C6.4c-1.3%2C0-2.6-0.4-3.7-0.9c0%2C0%2C0%2C0.1%2C0%2C0.1c0%2C3.7%2C2.8%2C6.8%2C6.6%2C7.5%20%20c-0.7%2C0.2-1.4%2C0.3-2.2%2C0.3c-0.5%2C0-1-0.1-1.5-0.1c1%2C3%2C4.1%2C5.2%2C7.7%2C5.3c-2.8%2C2-6.3%2C3.3-10.2%2C3.3c-0.7%2C0-1.3%2C0-2-0.1%20%20c3.6%2C2.2%2C7.9%2C3.4%2C12.6%2C3.4c15.1%2C0%2C23.3-11.6%2C23.3-21.7c0-0.3%2C0-0.7%2C0-1C-237.5%2C398.3-236.1%2C397-235%2C395.5z%22%0A%20%20%20%20%20fill%3D%22%23343434%22%0A%20%20%20%20%20id%3D%22twitter%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 0 0 transparent;
  background-size: 20px 20px;
}

.social-LinkedIn a {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-90%20572%2040%2040%22%0A%20%20%20viewBox%3D%22-90%20572%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20%20%20d%3D%22M-50%2C596.3v14.8h-8.6v-13.8c0-3.5-1.2-5.8-4.3-5.8c-2.4%2C0-3.8%2C1.6-4.4%2C3.1%20%20%20c-0.2%2C0.6-0.3%2C1.3-0.3%2C2.1v14.4h-8.6c0%2C0%2C0.1-23.4%2C0-25.8h8.6v3.7c0%2C0%2C0%2C0.1-0.1%2C0.1h0.1V589c1.1-1.8%2C3.2-4.3%2C7.7-4.3%20%20%20C-54.2%2C584.7-50%2C588.4-50%2C596.3z%20M-85.1%2C572.9c-2.9%2C0-4.9%2C1.9-4.9%2C4.5c0%2C2.5%2C1.9%2C4.5%2C4.7%2C4.5h0.1c3%2C0%2C4.9-2%2C4.9-4.5%20%20%20C-80.4%2C574.8-82.2%2C572.9-85.1%2C572.9z%20M-89.5%2C611.1h8.6v-25.8h-8.6V611.1z%22%0A%20%20%20%20%20%20%20fill%3D%22%23343434%22%0A%20%20%20%20%20%20%20id%3D%22LinkedIn%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 0 0 transparent;
  background-size: 20px 20px;
}

.social-YouTube a {
  background: url('data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-260%20402%2040%2040%22%0A%20%20%20viewBox%3D%22-260%20402%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20d%3D%22M-220%2C414c0-3.3-2.7-6-6-6h-28c-3.3%2C0-6%2C2.7-6%2C6v16c0%2C3.3%2C2.7%2C6%2C6%2C6h28%20%20c3.3%2C0%2C6-2.7%2C6-6V414z%20M-244%2C428.8v-15.1l11.4%2C7.5L-244%2C428.8z%22%0A%20%20%20%20%20fill%3D%22%23343434%22%0A%20%20%20%20%20id%3D%22YouTube%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 0 0 transparent;
  background-size: 20px 20px;
}

.social-Facebook a:hover,
.social-Twitter a:hover,
.social-LinkedIn a:hover,
.social-YouTube a:hover {
  opacity: 0.8;
  /*-webkit-filter: hue-rotate(175deg) saturate(5);
filter: hue-rotate(175deg) saturate(5);*/
}

/*#block-views-social-links-block .views-row span.social-Facebook a {
	background-position:0 0;
}
#block-views-social-links-block .views-row span.social-Facebook a:hover {
  background-position:0 -40px;
}
#block-views-social-links-block .views-row span.social-Twitter a {
	background-position:-40px 0;
}
#block-views-social-links-block .views-row span.social-Twitter a:hover {
	background-position:-40px -40px;
}
#block-views-social-links-block .views-row span.social-LinkedIn a {
	background-position:-77px 0;
}
#block-views-social-links-block .views-row span.social-LinkedIn a:hover {
	background-position:-77px -40px;
}*/
/*****   FOOTER - MENU   *****/
#zone-footer-2-wrapper {
  background-color: #f7f8ff;
}

#block-menu-menu-footer {
  font-size: 0.875em;

  a,
  a:link,
  a:visited {
    padding: 5px 2px;
    border: 1px dotted transparent;
    color: $ruby_red;

    &:focus-visible {
      outline: $outline-border;
      outline-offset: $outline-offset-border;
    }

    &:hover {
      color: $tyrian_purple;
    }
  }

  ul.menu {
    list-style: none;
    padding: 0;
    margin: 0 0 0.625em;
    text-align: center;

    li {
      border-right: 1px solid #8A868A;
      display: inline-block;
      margin: 0 0.5em 0.325em 0;
      padding: 0 0.5em 0 0;

      &.last {
        border: none;
      }
    }
  }

  #copyright,
  #manta-ray-media {
    text-align: center;
  }

  #copyright {
    margin: 0 0 1em;
  }

  #manta-ray-media {
    strong {
      display: block;
      font-weight: 400;
    }

    span.ext {
      display: none;
    }
  }
}


@media (min-width: 740px) {
  /*****   FOOTER   *****/
  #section-footer #block-block-1,
  #section-footer #block-views-social-links-block h2.block-title {
    display: inherit;
  }
  #section-footer #block-block-2 {
    display: none;
  }
  /*****   FOOTER - MAP   *****/
  #region-footer-first a {
    display: block;
  }
  #region-footer-first a.spamspan.mailto {
    display: inline-block;
    margin-left: 5px;
  }
  #region-footer-first a:hover .find-us-arrow {
    background-position: 0 -124px;
  }
  #region-footer-first a:hover .find-us-text {
    background-color: $jet; //#730732;
  }
  #region-footer-first .find-us-map {
    /*background: url("../img/find-us-map.jpg") no-repeat scroll 50% 100% transparent;*/
    display: block;
    height: 260px;
    width: 200px;
  }
  #region-footer-first .find-us-arrow {
    /*background: url("../img/arrows-square.png") no-repeat scroll 0 -62px transparent;*/
    display: block;
    height: 42px;
    width: 42px;
  }
  #region-footer-first .find-us-text {
    background-color: $ruby_red; //#e31735;
    color: #ffffff;
    display: block;
    padding: 10px;
    width: 180px;
  }
  #region-footer-first .find-us-box {
    text-align: center;
  }
  /*****   FOOTER - CONTACT   *****/

  /*****   FOOTER - SOCIAL   *****/
  #block-views-social-links-block {
    display: block;
  }
  #block-views-social-links-block .views-row {
    float: left;
  }
  #block-views-social-links-block .views-row a .ext {
    display: none;
  }
  #block-views-social-links-block .views-row span,
  #block-views-social-links-block .views-row.views-row-last span {
    padding: 0;
  }
  #block-views-social-links-block .views-row span a {
    display: block;
    width: 90px;
    height: 90px;
    text-indent: -999em;
    overflow: hidden;
    color: white;
    font-size: 2em;
  }
  #block-views-social-links-block .social-Facebook a {
    background: url('data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%0A%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-260%20402%2040%2040%22%0A%20%20%20viewBox%3D%22-260%20402%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20%20%20d%3D%22M-220%2C408.7c0-3.5-3.2-6.7-6.7-6.7h-26.7c-3.5%2C0-6.7%2C3.2-6.7%2C6.7v26.7%20%20%20c0%2C3.5%2C3.2%2C6.7%2C6.7%2C6.7h13.3v-15.1h-4.9v-6.7h4.9v-2.6c0-4.5%2C3.4-8.5%2C7.5-8.5h5.4v6.7h-5.4c-0.6%2C0-1.3%2C0.7-1.3%2C1.8v2.7h6.7v6.7%20%20%20h-6.7V442h7.1c3.5%2C0%2C6.7-3.2%2C6.7-6.7V408.7z%22%0A%20%20%20%20%20%20%20fill%3D%22%23ffffff%22%0A%20%20%20%20%20%20%20id%3D%22Facebook%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 50% 50% #36559E;
    background-size: 40px 40px;
  }
  #block-views-social-links-block .social-Twitter a {
    background: url('data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-275%20387%2040%2040%22%0A%20%20%20viewBox%3D%22-275%20387%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20d%3D%22M-235%2C395.5c-1.5%2C0.6-3.1%2C1-4.7%2C1.2c1.7-0.9%2C3-2.4%2C3.6-4.2%20%20c-1.6%2C0.9-3.3%2C1.5-5.2%2C1.9c-1.5-1.5-3.6-2.4-6-2.4c-4.5%2C0-8.2%2C3.4-8.2%2C7.6c0%2C0.6%2C0.1%2C1.2%2C0.2%2C1.7c-6.8-0.3-12.8-3.3-16.9-8%20%20c-0.7%2C1.1-1.1%2C2.4-1.1%2C3.8c0%2C2.6%2C1.4%2C5%2C3.7%2C6.4c-1.3%2C0-2.6-0.4-3.7-0.9c0%2C0%2C0%2C0.1%2C0%2C0.1c0%2C3.7%2C2.8%2C6.8%2C6.6%2C7.5%20%20c-0.7%2C0.2-1.4%2C0.3-2.2%2C0.3c-0.5%2C0-1-0.1-1.5-0.1c1%2C3%2C4.1%2C5.2%2C7.7%2C5.3c-2.8%2C2-6.3%2C3.3-10.2%2C3.3c-0.7%2C0-1.3%2C0-2-0.1%20%20c3.6%2C2.2%2C7.9%2C3.4%2C12.6%2C3.4c15.1%2C0%2C23.3-11.6%2C23.3-21.7c0-0.3%2C0-0.7%2C0-1C-237.5%2C398.3-236.1%2C397-235%2C395.5z%22%0A%20%20%20%20%20fill%3D%22%23ffffff%22%0A%20%20%20%20%20id%3D%22twitter%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 50% 50% #009cec;
    background-size: 40px 40px;
  }
  #block-views-social-links-block .social-LinkedIn a {
    background: url('data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-90%20572%2040%2040%22%0A%20%20%20viewBox%3D%22-90%20572%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20%20%20d%3D%22M-50%2C596.3v14.8h-8.6v-13.8c0-3.5-1.2-5.8-4.3-5.8c-2.4%2C0-3.8%2C1.6-4.4%2C3.1%20%20%20c-0.2%2C0.6-0.3%2C1.3-0.3%2C2.1v14.4h-8.6c0%2C0%2C0.1-23.4%2C0-25.8h8.6v3.7c0%2C0%2C0%2C0.1-0.1%2C0.1h0.1V589c1.1-1.8%2C3.2-4.3%2C7.7-4.3%20%20%20C-54.2%2C584.7-50%2C588.4-50%2C596.3z%20M-85.1%2C572.9c-2.9%2C0-4.9%2C1.9-4.9%2C4.5c0%2C2.5%2C1.9%2C4.5%2C4.7%2C4.5h0.1c3%2C0%2C4.9-2%2C4.9-4.5%20%20%20C-80.4%2C574.8-82.2%2C572.9-85.1%2C572.9z%20M-89.5%2C611.1h8.6v-25.8h-8.6V611.1z%22%0A%20%20%20%20%20%20%20fill%3D%22%23ffffff%22%0A%20%20%20%20%20%20%20id%3D%22LinkedIn%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 50% 50% #0097BF;
    background-size: 40px 40px;
  }
  #block-views-social-links-block .social-YouTube a {
    background: url('data:image/svg+xml;charset=utf-8,%3Csvg%0A%20%20%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%0A%20%20%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%0A%20%20%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%0A%20%20%20xmlns%3Asvg%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20xml%3Aspace%3D%22preserve%22%0A%20%20%20enable-background%3D%22new%20-260%20402%2040%2040%22%0A%20%20%20viewBox%3D%22-260%20402%2040%2040%22%0A%20%20%20y%3D%220px%22%0A%20%20%20x%3D%220px%22%0A%20%20%20version%3D%221.1%22%3E%3Cmetadata%3E%3Crdf%3ARDF%3E%3Ccc%3AWork%0A%20%20%20%20%20%20%20%20%20rdf%3Aabout%3D%22%22%3E%3Cdc%3Aformat%3Eimage%2Fsvg%2Bxml%3C%2Fdc%3Aformat%3E%3Cdc%3Atype%0A%20%20%20%20%20%20%20%20%20%20%20rdf%3Aresource%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Fdcmitype%2FStillImage%22%20%2F%3E%3Cdc%3Atitle%3E%3C%2Fdc%3Atitle%3E%3C%2Fcc%3AWork%3E%3C%2Frdf%3ARDF%3E%3C%2Fmetadata%3E%3Cdefs%20%2F%3E%3Cpath%0A%20%20%20%20%20d%3D%22M-220%2C414c0-3.3-2.7-6-6-6h-28c-3.3%2C0-6%2C2.7-6%2C6v16c0%2C3.3%2C2.7%2C6%2C6%2C6h28%20%20c3.3%2C0%2C6-2.7%2C6-6V414z%20M-244%2C428.8v-15.1l11.4%2C7.5L-244%2C428.8z%22%0A%20%20%20%20%20fill%3D%22%23ffffff%22%0A%20%20%20%20%20id%3D%22YouTube%22%20%2F%3E%3C%2Fsvg%3E') scroll no-repeat 50% 50% #d03822;
    background-size: 40px 40px;
  }
}
